import PtApiClient from 'plant-api-client';
import ContentSelectorDialog from '../components/ContentSelectorDialog';
import PServiceContent from 'plant-common/src/services/PServiceContent';
import PServiceXAPI from 'plant-common/src/services/PServiceXAPI';
import {libraryApi} from '@/api/index.js';
import store from '@/store';
import ObjectivePreview from '@/components/lessonPlan/components/slides/objectives/ObjectivePreview.vue';
import componentModal from '@/utils/componentModal.js';
import {getContentId, getMajorVersion, getMinorVersion, getType} from '@/utils/dbTool';
import {STORE_LIBRARY_NAME} from '@/store_constants/library';
import * as CommonApps from '@/pages/commonApps';

/**
 * @returns <Array>
 */
const findContentDriver = function (req) {
  if (req.library_id) {
    if (req.open_custom_id) {
      return libraryApi.searchContentByCustomId(req.library_id, { custom_id: req.open_custom_id }).then(r => {
        r.data.header.library_id = req.library_id
        return r.data
      })
    } else if (req.custom_id) {
      return libraryApi.searchContentByCustomId(req.library_id, { custom_id: req.custom_id }).then(r => {
        r.data.header.library_id = req.library_id
        return [r.data]
      })
    } else {
      return libraryApi.searchContentByTypeAndText(req.library_id, req.type, req.text, req.hotspot).then(r => r.data)
    }
  } else {
    if (req.open_custom_id) {
      return PtApiClient.getContentByCustomId(req.open_custom_id)
    } else if (req.custom_id) {
      return PtApiClient.getContentByCustomId(req.custom_id).then(r => [r])
    } else {
      return PtApiClient.getContentWithTypeOrText(req.type, req.text, req.hotspot)
    }
  }
}

function openApp(app, locator) {
  const href = CommonApps.getAppUrl(app, locator.libraryId, locator.contentId, locator.revision);
  window.open(href, "_blank");
}

function isObjectId(string) {
  return string.match(/^[0-9a-fA-F]{24}$/);
}

function isPath(string) {
  const regexCrossLibrary = new RegExp('plant(|-ref|-xref|-link)://');
  const isAbsolutePath = string.startsWith("plant://");

  return regexCrossLibrary.test(string) || isAbsolutePath;
}

function getURLFromString(string, library_id, tag) {
  if (isObjectId(string)) {
    return `/content/${library_id}/${string}/latest${tag ? '#' + tag : ''}`;
  }

  if (isPath(string)) {
    const [, , library_id, content, content_id, major_revision, minor_revision] = string.split('/');
    return `/content/${library_id}/${content_id}/${major_revision}/${minor_revision}${tag ? '#' + tag : ''}`;
  }

  const [, , content_id, major_revision, minor_revision] = string.split('/');
  return `/content/${library_id}/${content_id}/${major_revision}/${minor_revision}${tag ? '#' + tag : ''}`;
}

function getURLFromHeader(header, libraryId, tag) {
  const { major_revision, minor_revision } = header;
  const library_id = header.library_id ? header.library_id : libraryId;
  const content_id = header.metadata.content_id;

  return `/content/${library_id}/${content_id}/${major_revision}/${minor_revision}${tag ? '#' + tag : ''}`;
}

function getURLFromObject(object, library_id, tag) {

  if (object.$origin) {
    return getURLFromString(object.$origin, library_id);
  }

  return getURLFromHeader(object.header, library_id, tag);
}

function getLocatorFromHeader(header, libraryId) {
  const { major_revision, minor_revision } = header;
  const library_id = header.library_id ? header.library_id : libraryId;
  const content_id = header.metadata.content_id;

  return {
    libraryId: library_id,
    contentId: content_id,
    revision: `${major_revision}.${minor_revision}`
  };
}

function openInNewTabFromURL(url, library_id, tag) {
  window.open(getURLFromString(url, library_id, tag));
}

function openInNewTabFromContent(content, library_id, tag) {
  const libraryId = content.header.metadata.library_id || library_id;
  const urlToOpen = getURLFromObject(content, libraryId, tag);
  const contentType = getType(content);

  if (CommonApps.isAppAvailableAsNewApp(contentType)) {
    const locator = getLocatorFromHeader(content.header, library_id);
    openApp(CommonApps.getAppName(contentType), locator);
  } else {
    window.open(urlToOpen)
  }
}

/**
 * Implements openContent service
 * @param {string | Object} urlOrContent  - A PLANT url, content object or content ID
 * @param {'tab' | 'dialog' | 'current'} target - Where to open the
 * @param {string} tag - Tag "from" content opened was called [Optional]
 * @description Process urlOrContent to check if it is:
 * - MongoId (string): contentID, so we open latest version of contentID
 * - Url (string): split url content (contentId, major and minor version)
 * - Content (Object): get $origin prop (it could be from current library or cross-reference)
 * @return {void} Open content in a new "tab" or "dialog"
 */
const openContentDriver = async function (urlOrContent, target, tag) {
  let library_id = store.state[STORE_LIBRARY_NAME].settings._id;
  let content;

  switch (target) {
    case "tab":
      if (typeof urlOrContent == "string") {
        openInNewTabFromURL(urlOrContent, library_id, tag);
        break;
      }

      openInNewTabFromContent(urlOrContent, library_id, tag);
      break
    case "dialog":
      if (typeof urlOrContent == "string") {
        if (isObjectId(urlOrContent)) {
          content = await PtApiClient.getContentById(urlOrContent)
        } else {
          const [, , content_id, major_revision, minor_revision] = urlOrContent.split("/")
          content = await PtApiClient.getRevisionByContentId(content_id, major_revision, minor_revision)
        }
      } else {
        content = urlOrContent
      }
      await componentModal(ObjectivePreview, { content })
      break
    case "current":
      if (urlOrContent.library_id) {
        library_id = urlOrContent.library_id
      }
      window.open(`/content/${library_id}/${urlOrContent.itemId}/latest?from=${urlOrContent.from}`, "_self")
      break
  }
}

const getLatestDriver = async function (arrayCustomIds) {
  return await PtApiClient.getLatestVersions(arrayCustomIds)
}

const selectContentDriver = function (filters) {
  return ContentSelectorDialog(filters)
}

const submitStatementDriver = function (req) {
  // Call PtApi endpoint
  return new Promise(function (resolve, reject) {
    resolve(req)
  })
}

const uploadFileServiceDriver = async function (file, contentId) {
  const contentIdentification = contentId || store.state.content.itemRevision.header.metadata.content_id
  const { filename, url } = await PtApiClient.uploadDraftFile(contentIdentification, file)
  return { filename, url }
}

const getDependentsDriver = async function (content) {
  return await PtApiClient.getContentDependents(
    getContentId(content),
    getMajorVersion(content),
    getMinorVersion(content)
  )
}

const getContentFromRefDriver = async function (ref) {
  if (ref) {
    const regexAbsolute = new RegExp("plant://")
    const regexRelative = new RegExp("plant:/content/")
    if (regexRelative.test(ref)) {
      var [, , content_id, major_revision, minor_revision] = ref.split("/")
      if (content_id && major_revision && minor_revision) {
        const content = await PtApiClient.getRevisionByContentId(content_id, major_revision, minor_revision)
        return content
      } else {
        return new Error("Malparsed $ref: id, major_version, minor_version not set")
      }
    } else if (regexAbsolute.test(ref)) {
      var [protocol, empty, library_id, content, content_id, major_revision, minor_revision] = ref.split("/")
      if (library_id && content_id && major_revision && minor_revision) {
        const { data } = await libraryApi.getContentByRevision(library_id, content_id, major_revision, minor_revision)
        return data
      } else {
        return new Error("Malparsed $ref: library_id, id, major_version, minor_version not set")
      }
    }
  } else {
    return new Error("Invalid $ref")
  }
}

PServiceContent.registerDriver({
  findContentDriver,
  selectContentDriver,
  getLatestDriver,
  openContentDriver,
  uploadFileServiceDriver,
  getDependentsDriver,
  getContentFromRefDriver
})
PServiceXAPI.registerDriver({ submitStatementDriver })
