<template>
	<v-card class="lists-wrapper pa-2">
		<v-card-title class="headline title">Customize related content viewer</v-card-title>

		<v-card-text class="lists">
			<v-layout row wrap class="h-100">
				<!-- Lista de origen -->
				<v-flex xs5 class="h-100">
					<p class="list-title">Related Content</p>

					<v-list dense class="origin-list">
						<p v-if="!localOrigin.length">The list is empty</p>

						<template v-else v-for="(content, index) in localOrigin">
							<p-content-tile :value="content" :key="index">
								<template v-slot:append>
									<v-list-tile-action class="origin-list__actions">
										<v-btn icon ripple @click="openContent(content)">
											<v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
										</v-btn>

										<v-btn icon @click="moveToTarget(index)">
											<v-icon>mdi-arrow-right</v-icon>
										</v-btn>
									</v-list-tile-action>
								</template>
							</p-content-tile>
						</template>
					</v-list>
				</v-flex>

				<!-- Botones en el centro -->
				<v-flex xs2 class="middle-buttons">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn :disabled="!localOrigin.length" color="primary" class="mb-2" v-on="on" @click="moveAllToTarget">
								<v-icon>mdi-share-all-outline</v-icon>
							</v-btn>
						</template>

						<span>Move all elements to the right</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn :disabled="!localTarget.length" color="primary" v-on="on" @click="moveAllToOrigin">
								<v-icon>mdi-reply-all-outline</v-icon>
							</v-btn>
						</template>

						<span>Move all elements to the left</span>
					</v-tooltip>
				</v-flex>

				<!-- Lista target con draggable -->
				<v-flex xs5 class="h-100">
					<p class="list-title">Viewer Content</p>

					<v-list dense class="target-list">
						<p v-if="!localTarget.length">The list is empty</p>

						<draggable v-else v-model="localTarget">
							<p-content-tile class="d-block draggable-item" v-for="(content, index) in localTarget" :value="content" :key="index">
								<template v-slot:prepend>
									<v-list-tile-action>
										<v-btn icon @click="moveToOrigin(index)">
											<v-icon>mdi-arrow-left</v-icon>
										</v-btn>
									</v-list-tile-action>
								</template>

								<template v-slot:append>
									<v-list-tile-action>
										<v-btn :disabled="!content.isActive" icon ripple @click="openContent(content)">
											<v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
										</v-btn>
									</v-list-tile-action>
								</template>
							</p-content-tile>
						</draggable>
					</v-list>
				</v-flex>
			</v-layout>
		</v-card-text>

		<v-card-actions class="footer">
			<v-btn color="error" @click="$emit('close')">
				<v-icon left>mdi-cancel</v-icon>Cancel
			</v-btn>

			<v-btn color="success" @click="$emit('update:target', localTarget)">
				<v-icon left>mdi-check</v-icon>Set
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import draggable from 'vuedraggable';
import { PContentTile } from 'plant-common';
import PServiceContent from 'plant-common/src/services/PServiceContent';

export default {
	name: 'DraggableListTwoColumns',
	components: { PContentTile, draggable },
	props: {
		origin: {
			type: Array,
			required: true
		},
		target: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			localTarget: [],
			localOrigin: []
		};
	},
	mounted() {
		this.localOrigin = [...this.origin];
		this.localTarget = [...this.target];
	},
	methods: {
		moveToTarget(index) {
			const item = this.localOrigin.splice(index, 1)[0];
			this.localTarget = [...this.localTarget, item];
		},
		moveToOrigin(index) {
			const item = this.localTarget.splice(index, 1)[0];

			if (!item.isActive) {
				return;
			}

			this.localOrigin = [...this.localOrigin, item];
		},
		moveAllToTarget() {
			this.localTarget = [...this.localTarget, ...this.localOrigin];
			this.localOrigin = [];
		},
		moveAllToOrigin() {
			this.localTarget = this.localTarget.filter(item => item.isActive);
			this.localOrigin = [...this.localOrigin, ...this.localTarget];
			this.localTarget = [];
		},
		openContent(content) {
			PServiceContent.openContent(content, 'tab');
		}
	}
};
</script>

<style scoped>
.lists-wrapper {
	height: 85vh;
	max-height: 800px;
}

.lists-wrapper .title {
	height: 10%;
}

.lists-wrapper .lists {
	height: 80%;
}

.lists-wrapper .list-title {
	margin-bottom: 1rem;
	height: 1rem;
}

.lists .origin-list,
.lists .target-list {
	border: 1px solid black;
	height: calc(100% - 2rem);
	overflow-y: auto;
}

.lists .origin-list > p,
.lists .target-list > p {
	font-weight: bold;
	text-align: center;
}

.origin-list .origin-list__actions {
	flex-direction: row;
	align-items: center;
}

.lists-wrapper .middle-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.target-list .draggable-item {
	cursor: grab;
}

.lists-wrapper .footer {
	height: 10%;
	display: flex;
	justify-content: end;
}
</style>
