import { Extensions } from 'plant-common';

export const apps = ['youtubeVideo', 'term'];

export function isAppAvailableAsNewApp(contentType) {
	const { VUE_APP_IP_APP_AVAILABLE } = window.__env__ || false;
	const IP_APP_AVAILABLE = VUE_APP_IP_APP_AVAILABLE === "true";

	if (IP_APP_AVAILABLE && contentType === 'lessonPlan') {
		return true;
	}

	return apps.includes(contentType);
}

export function getAppName(contentType) {
	const separator = ' ';
	const glue = '-';

	return Extensions[contentType].title.split(separator).join(glue).toLowerCase();
}

export function getAppUrl(appName, libraryId, contentId, revision) {
	return `/apps/${appName}-app/index.html?libraryId=${libraryId}&contentId=${contentId}&revision=${revision}`;
}
