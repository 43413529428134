<script>
export default {
  inject: ["blockController", "blockDefinitions"],
  data() {
    return {
      translateX: 0,
      translateY: 0
    }
  },
  computed: {
    parent() {
      return this.$refs.viewer
    },
    slideStyles() {
      const { backgroundColor, border, boxShadow, color } = this.settingStyles.slide
      return {
        outline: border,
        boxShadow,
        color,
        transform: `scale(${this.blockController.scale}) translate(${this.translateX}px, ${this.translateY}px)`,
        backgroundColor
      }
    },
    styleCanvas() {
      return {
        fontFamily: this.defaultBlockStyles.fontFamily,
        width: "100%",
        height: "100%"
      }
    }
  },
  mounted() {
    // Watch for changes in viewer div to fit slide in the screen
		const resizeObserver = new ResizeObserver(this.zoomFit);
		if (this.$refs.viewer) {
      resizeObserver.observe(this.$refs.viewer)

      this.$refs.viewer.onwheel = event => {
        if (!event.ctrlKey) return
        event.preventDefault()
				const newScale = this.blockController.scale + event.deltaY * -0.001;

				// Restrict scale
        this.blockController.scale = Math.min(Math.max(0.5, newScale), 4)
				const slide = this.$refs.canvas;
        this.blockController.origin.x = slide.getBoundingClientRect().x
        this.blockController.origin.y = slide.getBoundingClientRect().y
      }
      this.zoomFit()
    }
  },
  methods: {
    getBlockComponent(type) {
      return this.blockDefinitions[type].Block
    },
    zoomFit() {
      const viewer = this.$refs.viewer

			if (viewer) {
      	const slide = this.$refs.canvas
        const newScale = Math.min(
          viewer.offsetWidth / (slide.offsetWidth + 30),
          viewer.offsetHeight / (slide.offsetHeight + 30)
        )
        this.translateX = (viewer.offsetWidth / 2 - slide.offsetWidth / 2) / newScale
        this.translateY = (viewer.offsetHeight / 2 - slide.offsetHeight / 2) / newScale
        this.blockController.scale = newScale
      
        setTimeout(() => {
          const { width, height, x, y } = slide.getBoundingClientRect()
          this.blockController.origin.x = x
          this.blockController.origin.y = y
          this.blockController.viewerWidth = width
          this.blockController.viewerHeight = height
        }, 0);
      }
    }
  }
}
</script>

<style>
.lp-slide {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 32px;
  background: white;
}

.lp-slide-wrapper {
  position: absolute;
  top: 0px;
  bottom: 32px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background: #cecece;
}

.lp-slide-wrapper:focus {
  outline: none !important;
}

.lp-slide-wrapper.dark {
  background-color: rgb(66, 66, 66) !important;
}
</style>
