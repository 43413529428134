<template>
	<basic-block :value="value" :mode="mode">
		<template v-slot:main>
			<div :style="style" class="block-radiobutton-group">
				<fieldset>
					<div
						v-for="(item, index) of items"
						:key="index"
						@click.stop
						class="block-radiobutton"
						:style="`textAlign: ${value.data.style.textAlign}`"
					>
						<input type="radio" :id="`radio-${index}`" :value="index" v-model="selected" :checked="selected == index" />

						<label :for="`radio-${index}`">
							<div v-html="item"></div>
						</label>
					</div>
				</fieldset>
			</div>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import { PRichText, renderKatex } from 'plant-common';

export default {
	name: 'BlockRadiobutton',
	props: ['value', 'mode', 'blockVariables'],
	components: { BasicBlock, PRichText },
	data() {
		return {
			selected: -1,
			/* Size and position radio button was adapted to this default size */
			defaultSize: 46
		};
	},
	mounted() {
		if (this.$attrs.previousResponse) {
			if (this.blockVariables) {
				const questionChoices = this.blockVariables.find(variable => variable.name == this.value.data.items);
				this.selected = questionChoices.value.findIndex(choice => choice === this.$attrs.previousResponse);
			} else {
				return false;
			}
		}

		this.renderFormulas();
	},
	computed: {
		items() {
			if (this.blockVariables) {
				return this.blockVariables.find(variable => variable.name == this.value.data.items)?.value ?? false;
			} else {
				return false;
			}
		},
		style() {
			return {
				color: this.value.data.style.color,
				fontSize: `${this.value.data.style.fontSize}px`,
				fontFamily: this.value.data.style.fontFamily || 'inherit',
				alignItems: this.value.data.style.alignItems,
				padding: `${this.value.data.style.padding}px`,
				'--color-radio-button': this.value.data.style.color,
				'--zoom-radio-button': this.value.data.style.fontSize / this.defaultSize,
				'--margin-left-radio-button': `${this.value.data.style.fontSize * 1.2 - this.defaultSize}px`
			};
		},
		isViewMode() {
			return this.mode !== 'edit';
		},
		blockHasDelayAndIsViewMode() {
			return this.isViewMode && this.blockHasDelay(this.value);
		},
		parentId() {
			return `parent-${this.value.id}`;
		}
	},
	watch: {
		selected() {
			let userChoice = {};
			if (this.blockVariables) {
				userChoice = this.blockVariables.find(variable => variable.name == this.value.data.value);
				userChoice.value = this.selected;
			}
		}
	},
	methods: {
		blockHasDelay(block) {
			return (
				block.animations &&
				block.animations[0] &&
				block.animations[0].name !== 'none' &&
				block.animations[0].delay &&
				block.animations[0].delay !== 0
			);
		},
		renderFormulas() {
			const block = document.getElementById(this.parentId);

			if (this.blockHasDelayAndIsViewMode) {
				block.addEventListener('animationstart', () => {
					renderKatex(block);
				});
				block.addEventListener('animationend', () => {
					block.removeEventListener('animationstart', () => {});
					block.removeEventListener('animationend', () => {});
				});
			} else {
				renderKatex(block);
			}
		}
	}
};
</script>

<style>
.block-radiobutton-group {
	display: flex;
	height: 100%;
	margin-left: var(--margin-left-radio-button);
}
.block-radiobutton-group > fieldset {
	width: 100%;
	border: none;
}
.block-radiobutton {
	width: 100%;
	margin: 10px 0;
}
/* Radio button style */
/* https://www.a11ywithlindsey.com/blog/create-custom-keyboard-accessible-radio-buttons */
.block-radiobutton input[type='radio'] {
	display: none;
}
.block-radiobutton input[type='radio'] + label {
	position: relative;
	display: inline-block;
	cursor: pointer;
	margin-left: 50px;
}
/* label::before and label::after adapted to font-size 46px (defaultSize) */
.block-radiobutton input[type='radio'] + label::before {
	content: '';
	position: absolute;
	display: inline-block;
	left: -55px;
	top: 15px;
	border-radius: 50%;
	border: 1px solid #6f686a;
	width: 40px;
	height: 40px;
	background: transparent;
	zoom: var(--zoom-radio-button);
}
.block-radiobutton input[type='radio'] + label::after {
	content: '';
	position: absolute;
	display: inline-block;
	left: -47px;
	top: 23px;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	zoom: var(--zoom-radio-button);
}
.block-radiobutton input[type='radio']:checked + label::after {
	background: var(--color-radio-button);
}
.block-radiobutton input[type='radio']:focus + label::after {
	box-shadow: 0 0px 8px var(--color-radio-button);
}
.block-radiobutton p {
	margin-bottom: 0px;
}
</style>
