<template>
	<fragment>
		<v-menu offset-y>
			<template v-slot:activator="{ on }">
				<tip-tap-toolbar-button
					v-on="on"
					tooltip="Insert table"
					icon="mdi-table-large"
					:dark="dark">
				</tip-tap-toolbar-button>
			</template>
			<table-select-length :cols="10" :rows="10" @cellSelected="insertTable"></table-select-length>
		</v-menu>

		<v-menu offset-y>
			<template v-slot:activator="{ on }">
				<tip-tap-toolbar-button
					v-on="on"
					tooltip="Add"
					icon="mdi-table-large-plus"
					:dark="dark">
				</tip-tap-toolbar-button>
			</template>
			<v-list>
				<v-list-tile @click="editor.chain().focus().addColumnBefore().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-column-plus-before</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Add column before</v-list-tile-title>
				</v-list-tile>

				<v-list-tile @click="editor.chain().focus().addColumnAfter().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-column-plus-after</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Add column after</v-list-tile-title>
				</v-list-tile>

				<v-list-tile @click="editor.chain().focus().addRowBefore().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-row-plus-before</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Add row before</v-list-tile-title>
				</v-list-tile>

				<v-list-tile @click="editor.chain().focus().addRowAfter().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-row-plus-after</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Add row after</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>

		<v-menu offset-y>
			<template v-slot:activator="{ on }">
				<tip-tap-toolbar-button
					v-on="on"
					tooltip="Delete"
					icon="mdi-table-large-remove"
					:dark="dark">
				</tip-tap-toolbar-button>
			</template>
			<v-list>
				<v-list-tile @click="editor.chain().focus().deleteColumn().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-column-remove</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Delete column</v-list-tile-title>
				</v-list-tile>

				<v-list-tile @click="editor.chain().focus().deleteRow().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-row-remove</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Delete row</v-list-tile-title>
				</v-list-tile>

				<v-list-tile @click="editor.chain().focus().deleteTable().run()">
					<v-list-tile-avatar>
						<v-icon>mdi-table-large-remove</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title>Delete table</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>

		<tip-tap-toolbar-button
			tooltip="Merge cells"
			icon="mdi-table-merge-cells"
			@click="editor.chain().focus().mergeCells().run()"
			:dark="dark">
		</tip-tap-toolbar-button>

		<tip-tap-toolbar-button
			tooltip="Split cell"
			icon="mdi-table-split-cell"
			@click="editor.chain().focus().splitCell().run()"
			:dark="dark">
		</tip-tap-toolbar-button>
	</fragment>
</template>

<script>
import TipTapToolbarButton from 'plant-common/src/components/PRichText/TipTapToolbarButton.vue';
import { Fragment } from "vue-fragment";
import TableSelectLength from './TableSelectLength.vue';

export default {
	name: 'TiptapTableButtons' ,
	props: ['editor', 'dark'],
	components: { TableSelectLength, TipTapToolbarButton, Fragment },
	methods: {
		insertTable(rows, cols) {
			this.editor.chain().focus().insertTable({ rows: rows, cols: cols, withHeaderRow: true }).run();
		}
	}
};
</script>
