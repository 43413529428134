<template>
  <div class="lp-layouts-panel">
    <v-toolbar flat class="transparent">
      <v-toolbar-title class="lp-layouts-panel-title">Layouts Panel</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn flat v-on="on" class="lp-layouts-panel-layout-actions-button">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="lessonPlanController.changeToEditSlides">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-file-tree</v-icon>
                <span>Go to Slides</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile @click="addLayout">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-file-plus-outline</v-icon>
                <span>Add new Layout</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile @click="importLayouts">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-file-plus-outline</v-icon>
                <span>Import Interactive Presentation's Layouts</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <div
      v-if="(!layouts || layouts.length === 0) && (!importedLayouts || importedLayouts.length === 0)"
      style="color: white">
      <p class="ma-4 subheading">
        No layout added to this Interactive Presentation or imported from other Interactive Presentation.
      </p>
      <ul class="ma-4 body-2">
        <li>You can add a layout in Slide Properties section in the right panel.</li>
        <li>You can import Interactive Presentation layouts clicking the 3 dots at the top of this panel.</li>
      </ul>
    </div>
    <div v-else-if="!layouts || layouts.length === 0" style="color: white; text-align: center">
      <p class="ma-2">No local layouts added to this Interactive Presentation.</p>
      <p>
        <v-btn @click="addLayout" outline color="white">
          <v-icon left>mdi-plus</v-icon>
          Add local Layout
        </v-btn>
      </p>
    </div>

    <!-- This lesson plan -->
    <v-subheader @click="toggleCollapsed(0)" class="lp-layouts-panel-collapsed" v-if="layouts && layouts.length > 0">
      <v-icon small left> mdi-chevron-double-{{ collapsed.includes(0) ? "up" : "down" }}</v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">This Interactive Presentation</span>
        </template>
        <span>You can view and edit the layouts of this Interactive Presentation</span>
      </v-tooltip>
    </v-subheader>

    <Tree :value="layouts" :ondragend="ondragend" @input="handleTreeInput">
      <span slot-scope="{ node }">
        <span v-show="!collapsed.includes(0)" class="lp-layouts-panel-layout">
          <span class="lp-layouts-panel-layout-title">
            <layout-detail
              :layout="node"
              :is-active="isSelected(node)"
              :is-edit-mode="isSelected(node) && lessonPlanController.editTitleMode"
              @slideChanged="$emit('slideChanged')" />
          </span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn flat v-on="on" class="lp-layouts-panel-layout-actions-button">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- Duplicate layout -->
              <v-list-tile @click="cloneLayout(node)">
                <v-list-tile-title class="body-1">
                  <v-layout row align-center>
                    <v-icon class="mr-2">mdi-content-duplicate</v-icon>
                    <span>Duplicate</span>
                  </v-layout>
                </v-list-tile-title>
              </v-list-tile>
              <!-- Delete layout -->
              <v-list-tile @click="removeLayout(node)">
                <v-list-tile-title class="body-1">
                  <v-layout row align-center>
                    <v-icon class="mr-2">mdi-trash-can</v-icon>
                    <span>Remove</span>
                  </v-layout>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </span>
      </span>
    </Tree>

    <v-divider></v-divider>

    <!-- New version imported layouts form ips -->
    <div v-for="(template, index) in templates" :key="template.id">
      <v-subheader
        @click="toggleCollapsed(template.id)"
        class="lp-layouts-panel-collapsed lp-layouts-panel-imported-first">
        <v-icon small left> mdi-chevron-double-{{ collapsed.includes(template.id) ? "up" : "down" }}</v-icon>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" v-if="template.reference && template.reference.header">
              {{ template.reference.header.metadata.custom_id }} - {{ template.reference.header.major_revision }}.{{
                template.reference.header.minor_revision
              }}
            </span>
            <span v-on="on" v-else> Imported template </span>
          </template>
          <span>You can only view the layouts of this Interactive Presentation</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn flat icon>
          <v-icon @click="removeTemplate(index)">mdi-delete-outline</v-icon>
        </v-btn>
      </v-subheader>

      <v-subheader class="template-content-imported" v-show="!collapsed.includes(template.id)">{{
        templateContentImportedFeedback(template)
      }}</v-subheader>

      <div
        v-for="layout in importedLayoutsBy(template.id)"
        :key="layout.id"
        v-show="!collapsed.includes(template.id)"
        class="lp-layouts-panel-layout">
        <div class="lp-layouts-panel-layout-title">
          <layout-detail
            :layout="layout"
            :is-active="isSelected(layout)"
            :is-edit-mode="isSelected(layout) && lessonPlanController.editTitleMode"
            :imported="true"
            @slideChanged="$emit('slideChanged')" />
        </div>
      </div>
    </div>

    <v-dialog v-model="importModal" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          {{ importMessage }}...
          <v-progress-linear color="white" v-model="importProgress"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- User feedback -->
    <v-dialog v-model="userFeedbackModal" width="500">
      <v-card color="primary" dark>
        <v-card-text class="text-center headline">
          {{ userFeedbackModalMessage }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getTitle } from '../../../../utils/dbTool.js';
import LayoutDetail from '../slides/tree/LayoutDetail.vue';
import { Layout, Slide, Template } from '../../models/models.js';
import { cloneDeep } from '../../utils/utils.js';
import uuid from '../../../../utils/uuid.js';
import PServiceContent from 'plant-common/src/services/PServiceContent';
import { resetVisitedSlide } from '../../models/upgradeModel.js';
import { PtModal } from 'plant-common';
import ModalImportContent from './ModalImportContent.vue';
import { Draggable, Tree } from 'he-tree-vue';

export default {
  inject: ["lessonPlanController"],
  mixins: [PtModal],
  components: { LayoutDetail, Tree: Tree.mixPlugins([Draggable]) },
  data() {
    return {
      collapsed: [],
      importMessage: "Importing",
      importModal: false,
      importProgress: 0,
      userFeedbackModal: false,
      userFeedbackModalMessage: ""
    }
  },
  computed: {
    layouts() {
      return this.lessonPlanController.layouts
    },
    importedLayouts() {
      return this.lessonPlanController.importedLayouts
    },
    templates() {
      return this.lessonPlanController.lessonPlanContents.templates
    }
  },
  methods: {
		getImportableOptionsFrom(interactivePresentation) {
			const nativeLayouts = interactivePresentation.body.contents.layouts.filter(layout => layout.imported === false);
			const nativeGalleries = interactivePresentation.body.contents.galleries.filter(gallery => gallery.imported === false);
			const nativeColorPalette = interactivePresentation.body.contents.settings.styles.colors;
			const nativeFontFamily = interactivePresentation.body.contents.settings.styles.block.fontFamily;

			return { nativeLayouts, nativeGalleries, nativeColorPalette, nativeFontFamily };
		},
		async contentToImportFrom(interactivePresentation) {
			const contentToImport = await this.$modal(ModalImportContent, { template: interactivePresentation });
			return contentToImport;
		},
    ondragend(tree, store) {
      return store.targetPath.length === 1
    },
    handleTreeInput(treeData) {
      this.lessonPlanController.lessonPlanContents.layouts = treeData.concat(this.importedLayouts)
    },
    importedLayoutsBy(templateId) {
      return this.lessonPlanController.importedLayouts.filter(layout => layout.imported === templateId)
    },
    getTitle: getTitle,
    templateContentImportedFeedback(template) {
      if (!template.hasOwnProperty("contentImported")) return ""

      let contentImported = ""
      Object.keys(template.contentImported).map(content => {
        if (template.contentImported[content]) contentImported += `${content}, `
      })
      return `(${contentImported} imported)`
    },
    toggleCollapsed(id) {
      const collapsedIndex = this.collapsed.findIndex(c => c === id)
      if (collapsedIndex > -1) {
        this.collapsed.splice(collapsedIndex, 1)
      } else {
        this.collapsed.push(id)
      }
    },
    isSelected(layout) {
      return this.lessonPlanController.selectedSlide && layout.slide.id === this.lessonPlanController.selectedSlide.id
    },
    addLayout() {
      const layout = new Layout()
      layout.slide = new Slide()
      this.lessonPlanController.lessonPlanContents.layouts.push(layout)
    },
    cloneLayout(layout) {
      const newLayout = cloneDeep(layout)
      newLayout.id = uuid()
      newLayout.slide.id = uuid()
      // Clean unnecessary children information
      newLayout.slide.children = false
      this.lessonPlanController.lessonPlanContents.layouts.push(newLayout)
    },
    removeLayout(layout) {
      const layoutIndex = this.lessonPlanController.lessonPlanContents.layouts.findIndex(
        lessonLayout => lessonLayout.id === layout.id
      )
      if (layoutIndex > -1) {
        this.lessonPlanController.lessonPlanContents.layouts.splice(layoutIndex, 1)
        if (layoutIndex !== 0) {
          this.lessonPlanController.selectLayoutById(
            this.lessonPlanController.lessonPlanContents.layouts[layoutIndex - 1].id,
            false
          )
          this.$emit("slideChanged")
        } else if (this.lessonPlanController.lessonPlanContents.layouts[0]) {
          this.lessonPlanController.selectLayoutById(this.lessonPlanController.lessonPlanContents.layouts[0].id, false)
          this.$emit("slideChanged")
        } else {
          this.lessonPlanController.selectedSlide = null
        }
      }
    },
    userMessage(message = "") {
      this.userFeedbackModalMessage = message
      this.userFeedbackModal = true
    },
		checkTemplateAlreadyImported(template) {
			return this.templates.find(
				temp =>
					temp.reference.header &&
					temp.reference.header.metadata.custom_id === template.reference.header.metadata.custom_id
			);
		},
		setImportMessage(message) {
			this.importMessage = message;
		},
		async copyLayoutsToImportToLocal(nativeLayouts, template) {
			this.importProgress = 0

			for (let i = 0; i < nativeLayouts.length; i++) {
				this.importProgress = ((i + 1) / nativeLayouts.length) * 100;
				resetVisitedSlide(nativeLayouts[i].slide);
				await this.lessonPlanController.addImportedLayout(nativeLayouts[i], template.id);
			}
		},
		async copyGalleriesToImportToLocal(nativeGalleries, template) {
			this.importProgress = 0

			for (let j = 0; j < nativeGalleries.length; j++) {
				this.importProgress = ((j + 1) / nativeGalleries.length) * 100;
				await this.lessonPlanController.addImportedGallery(nativeGalleries[j], template.id);
			}
		},
		async setImportContent(contentToImport, template, importableOptions) {
			const { nativeLayouts, nativeGalleries, nativeColorPalette, nativeFontFamily } = importableOptions;

			for (const content of contentToImport) {
				template.contentImported[content] = true;
				switch (content) {
					case 'layouts':
						this.setImportMessage('Importing layouts');

						await this.copyLayoutsToImportToLocal(nativeLayouts, template);
						break;

					case 'galleries':
						this.setImportMessage('Importing galleries blocks');

						await this.copyGalleriesToImportToLocal(nativeGalleries, template);
						break;

					case 'colorPalette':
						this.setImportMessage('Importing color palette');

						this.lessonPlanController.setColorPalette(nativeColorPalette);
						break;

					case 'fontFamily':
						this.setImportMessage('Importing font family');

						this.lessonPlanController.setFontFamily(nativeFontFamily);
						break;

					default:
						break;
				}
			}
		},
		async importLayouts() {
      const interactivePresentation = await PServiceContent.selectContent({ type: "lessonPlan" })
      if (!interactivePresentation) return

			const importableOptions = this.getImportableOptionsFrom(interactivePresentation);

			const notImportableOptions = !importableOptions.nativeLayouts.length && !importableOptions.nativeGalleries.length;
			if (notImportableOptions) {
        this.userMessage("There is no content to import from this Interactive Presentation")
        return
      }

      const contentToImport = await this.contentToImportFrom(interactivePresentation);
      if (!contentToImport) return

      const template = new Template(interactivePresentation)
      const templateAlreadyImported = this.checkTemplateAlreadyImported(template);
      if (templateAlreadyImported) {
        this.lessonPlanController.removeTemplate(templateAlreadyImported.id)
      }

      this.importModal = true
      this.lessonPlanController.addTemplate(template)

      await this.setImportContent(contentToImport, template, importableOptions);
      this.importModal = false
    },
    removeTemplate(index) {
      const templateId = this.lessonPlanController.lessonPlanContents.templates[index].id
      this.lessonPlanController.removeTemplate(templateId)
    }
  }
}
</script>

<style>
.lp-layouts-panel-title {
  flex-basis: 75%;
}

.lp-layouts-panel-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lp-layouts-panel-layout-title {
  flex-basis: 75%;
  margin-left: 20px;
  height: 40px;
}

.lp-layouts-panel-layout-actions-button.v-btn {
  min-width: 20px;
  padding: 0;
  margin-right: 0;
}

.lp-layouts-panel-layout-actions-button .v-icon {
  font-size: 18px;
}

.lp-layouts-panel-collapsed {
  cursor: pointer;
}

.lp-layouts-panel .theme--dark .v-text-field input {
  color: #ffffff !important;
}

.lp-layouts-panel .template-content-imported {
  margin-top: -20px;
  font-size: 0.8rem;
}
</style>
