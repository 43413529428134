import ptCoreClient from '../main';
import axios from 'axios';
const interceptors = {
	request: [],
	response: []
};

const $http = axios.create({
	timeout: 60 * 1000 //Axios config is used in LibraryApi setup
});

$http.interceptors.request.use(axios_config => {
	axios_config['baseURL'] = ptCoreClient.baseURL;
	if (ptCoreClient.token != '') axios_config.headers['Authorization'] = `Bearer ` + ptCoreClient.token;
	return axios_config;
});

$http.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		let err = Object.assign({}, error);
		interceptors.response.forEach(f => f(err));
		return Promise.reject(err);
	}
);

export default $http;
