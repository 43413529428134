<template>
	<div>
		<v-menu offset-y v-if="editingItemRevisionDraft">
			<template v-slot:activator="{ on }">
				<v-btn
					icon
					v-on="on"
					v-show="show_btns(editingItemRevisionDraft.approval_process.workflow)"
					tooltip="Workflow"
					height="22">
					<v-icon>more_vert</v-icon>
				</v-btn>
			</template>

			<v-list v-if="editingItemRevisionDraft">
				<v-list-tile
					v-for="(transition, i) in transitions.filter(t =>
						editingItemRevisionDraft.approval_process.workflow.transitions.includes(t.name)
					)"
					:key="i"
					@click="doTransition(transition.name)">
					<v-list-tile>
						<v-list-tile-avatar>
							<v-icon>{{ transition.icon }}</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title e2e-attr="workflow-option">{{ transition.title }}</v-list-tile-title>
					</v-list-tile>
				</v-list-tile>
				<template v-if="isLessonPlan">
					<v-divider></v-divider>
					<v-list-tile
						:href="`/apps/interactive-presentation-app/index.html?libraryId=${settings._id}&contentId=${itemId}&revision=draft`"
						target="_blank">
						<v-list-tile-avatar>
							<v-icon>mdi-rocket</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title>Open in Interactive Presentation app</v-list-tile-title>
					</v-list-tile>
				</template>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { PToolbarImage, PtModal, validate } from "plant-common"
import router from "@/router"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import {
	STORE_MODULE_EDITOR,
	STORE_CONTENT_NAME,
	STATE_ITEM_REVISION,
	STATE_ITEM_ID,
	STATE_CAN_EDIT,
	GETTER_ACTIVE_USER,
	ACTION_SUBMIT_TRANSITION,
	ACTION_UPLOAD_DRAFT,
	ACTION_UPDATE_ITEM_REVISION_METADATA
} from "../../../store_constants/content"
import { getType, getContentId } from "../../../utils/dbTool"
import WorkflowTypes from "../../workflow/constants/WorkflowTypes"

export default {
	components: { PToolbarImage },
	mixins: [PtModal],
	data() {
		return {
			transitions: [
				{
					title: "Send to review",
					name: "submitForReview",
					icon: "arrow_forward"
				},
				{
					title: "Send to approval",
					name: "submitForApproval",
					icon: "check"
				},
				{
					title: "Submit for Second Review",
					name: "submitForSecondReview",
					icon: "check"
				},
				{
					title: "Draft back to Author",
					name: "submitForCommentIncorporation",
					icon: "undo"
				},
				{
					title: "Draft back to Author",
					name: "submitForCommentsIncorporation",
					icon: "undo"
				},
				{
					title: "Approve Minor change",
					name: "approveMinorChange",
					icon: "check"
				},
				{
					title: "Approve Major change",
					name: "approveMajorChange",
					icon: "check"
				},
				{
					title: "Return to author",
					name: "reject",
					icon: "undo"
				},
				{
					title: "Submit for Reviewer Comments",
					name: "submitForReviewerComments",
					icon: "arrow_forward"
				},
				{
					title: "Submit for Approver Comments",
					name: "submitForApproverComments",
					icon: "arrow_forward"
				}
			]
		}
	},
	computed: {
		...mapState(STORE_CONTENT_NAME, {
			itemId: STATE_ITEM_ID,
			editingItemRevisionDraft: STATE_ITEM_REVISION,
			canEdit: STATE_CAN_EDIT
		}),
		...mapState(STORE_LIBRARY_NAME, {
			settings: STATE_PLANT_SETTINGS
		}),
		...mapGetters(STORE_CONTENT_NAME, {
			activeUser: GETTER_ACTIVE_USER
		}),
		isLessonPlan() {
			return getType(this.editingItemRevisionDraft) === "lessonPlan"
		}
	},
	methods: {
		...mapActions(STORE_MODULE_EDITOR, {
			submitTransition: ACTION_SUBMIT_TRANSITION,
			uploadItemDraft: ACTION_UPLOAD_DRAFT
		}),
		...mapActions(STORE_CONTENT_NAME, {
			updateItemRevisionMetadata: ACTION_UPDATE_ITEM_REVISION_METADATA
		}),
		show_btns(workflow) {
			if (workflow.contributors.find(contributor => contributor.email === this.activeUser.email)) {
				const userRole = workflow.contributors.find(contributor => contributor.email === this.activeUser.email).role

				if (userRole == workflow.assigned_to) {
					return true
				}
			}
			return false
		},
		async doTransition(transition) {
			const validator = validate(this.editingItemRevisionDraft)
			// Check content validity
			if (!validator.isValid) {
				// show modal info - content has required fields
				this.$modal("info", {
					title: "Can not continue the workflow",
					message: validator.message
						? validator.message
						: "Content is not valid to continue with its workflow. Review it, please",
					ok: {
						text: "Accept",
						color: "#2196f3",
						dark: true
					}
				})
				return
			}
			// Show confirmation modal for approve workflow state
			if (transition == ("approveMinorChange" || "approveMajorChange")) {
				let confirm = await this.$modal("confirm", {
					title: "Approve Content",
					message:
						"This action approves current content. It will be available in Content Explorer area. Do you want to continue?",
					accept: { text: "Yes" },
					cancel: { text: "No" }
				})
				if (!confirm) return
			}
			if (this.canEdit) {
				// Show summary of changes for author when changing the workflow state
				const metadata = await this.$modal("workflow", {
					selectedTab: "tab-datasheet-summary",
					title: `Workflow log`,
					approval_process: this.editingItemRevisionDraft.approval_process,
					workflows: WorkflowTypes,
					item: this.editingItemRevisionDraft,
					header: this.editingItemRevisionDraft.header,
					settings: this.settings,
					isDraft: true,
					isEdit: this.canEdit,
					dependents: [],
					hideClose: true
				})
				// Do not send instructionalSettings to update metadata
				delete metadata.instructionalSettings

				await this.updateItemRevisionMetadata({
					itemRevision: this.editingItemRevisionDraft,
					metadata: metadata
				})

				await this.uploadItemDraft()
			}
			this.submitTransition(transition)
				.then(itemDraft => {
					if (transition == 'approveMajorChange' || transition == 'approveMinorChange') {
						const routeData = router.resolve({
							name: 'Content Viewer Latest',
							params: { id: getContentId(itemDraft), library_id: this.settings._id }
						});
						window.location.href = routeData.href;
					} else {
						window.location.href = `/workflows`;
					}
				})
				.catch(e => console.log(e));
		},
		checkWFTransitions() {
			if (this.editingItemRevisionDraft) {
				this.show_btns(this.editingItemRevisionDraft.approval_process.workflow)
				// Include transitions if there are not set up already
				this.editingItemRevisionDraft.approval_process.workflow.transitions.forEach(transition => {
					if (!this.transitions.find(t => t.name == transition)) {
						var transitionText = transition.replace(/([A-Z])/g, " $1")
						var transitionFinalText = transitionText.charAt(0).toUpperCase() + transitionText.slice(1)
						this.transitions.push({
							title: transitionFinalText,
							name: transition,
							icon: "autorenew"
						})
					}
				})
			}
		}
	},
	mounted() {
		this.checkWFTransitions()
	},
	watch: {
		editingItemRevisionDraft: {
			inmediate: true,
			deep: true,
			handler() {
				this.checkWFTransitions()
			}
		}
	}
}
</script>

<style></style>
