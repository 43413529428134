<template>
	<v-card>
		<v-card-title class="pb-0">
			<span class="mr-1">Table</span>
			<span v-if="coordinatesHaveValues">{{ hoverCol + 1}} x {{ hoverRow + 1}}</span>
		</v-card-title>
		<v-card-text>
			<div class="table-container" @mouseleave="clearHighlight">
				<div
					v-for="(row, rowIndex) in rows"
					:key="`row-${rowIndex}`"
					class="table-row"
				>
					<div
						v-for="(col, colIndex) in cols"
						:key="`row-${rowIndex}-col-${colIndex}`"
						class="table-cell"
						@mouseover="highlightRange(rowIndex, colIndex)"
						@mouseleave="clearHighlight"
						@click="emitCellSelected(rowIndex, colIndex)"
						:class="{'highlighted': isHighlighted(rowIndex, colIndex)}"
					></div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "TableSelectLength",
	props: {
		rows: {
			type: Number,
			required: true,
		},
		cols: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			hoverRow: null,
			hoverCol: null,
		};
	},
	computed: {
		coordinatesHaveValues() {
			return this.hoverRow !== null && this.hoverCol !== null;
		}
	},
	methods: {
		emitCellSelected(rowIndex, colIndex) {
			this.$emit("cellSelected", rowIndex + 1, colIndex + 1);
		},
		highlightRange(row, col) {
			this.hoverRow = row;
			this.hoverCol = col;
		},
		clearHighlight() {
			this.hoverRow = null;
			this.hoverCol = null;
		},
		coordinatesAreInRange(row, col) {
			return row <= this.hoverRow && col <= this.hoverCol;
		},
		isHighlighted(row, col) {
			return this.coordinatesHaveValues && this.coordinatesAreInRange(row, col);
		}
	}
};
</script>

<style scoped>
.table-container {
	display: flex;
	flex-direction: column;
}

.table-row {
	display: flex;
}

.table-cell {
	width: 15px; /* Tamaño de la celda */
	height: 15px;
	border: 1px solid #000; /* Borde negro delgado */
	box-sizing: border-box; /* Asegura que el tamaño incluya el borde */
}

.table-cell.highlighted {
	background-color: #d3d3d3; /* Color de sombreado */
}
</style>
