<template>
  <pt-input-color
    :value="color"
    @input="setColor($event)"
    @menuOpened="$emit('menuOpened', $event)"
    :preset-colors="presetColors"
    :small="small"
    :closeButton="closeButton">
  </pt-input-color>
</template>

<script>
import { PtInputColor } from "plant-common"
export default {
  inject: ["lessonPlanController"],
  props: ["value", "small", "closeButton", "defaultValue"],
  components: { PtInputColor },
  data() {
    return {
      color: this.value || this.defaultValue || "#000000"
    }
  },
  computed: {
    presetColors() {
      return [...new Set(this.lessonPlanController.lessonPlanContents.settings.styles.colors)]
    }
  },
  watch: {
    value() {
      this.color = this.value || this.defaultValue || "#000000"
    }
  },
  methods: {
    setColor($event) {
      this.color = $event
      this.$emit("input", $event)
    }
  }
}
</script>
