<template>
  <fragment>
    <p-panel-properties-group title="Text alignment">
      <text-alignment-form :data="value.data" :show-justify="true" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Font">
      <font-global-form
        :data="value.data"
        :id="value.id"
        :type="value.type"
        :font-sizes-numbers="fontSizesNumbers"
        :showAlternatePadding="true"
        :showParagraphSpacing="true" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Background">
      <p-panel-properties>
        <p-property-tile icon="mdi-format-color-fill" title="Background Color">
          <preset-input-color v-model="value.data.backgroundColor" default-value="#00000000"></preset-input-color>
        </p-property-tile>
      </p-panel-properties>
    </p-panel-properties-group>
    <p-panel-properties-group title="Border">
      <p-panel-properties>
        <p-property-tile icon="mdi-border-outside" title="Border Color">
          <preset-input-color v-model="value.data.borderColor" default-value="#00000000"></preset-input-color>
        </p-property-tile>
      </p-panel-properties>
      <p-panel-properties>
        <p-property-tile icon="mdi-checkbox-blank-circle-outline" title="Border Radius">
          <p-panel-input-number style="max-width: 10rem;" :min="0" v-model="value.data.borderRadius"></p-panel-input-number>
        </p-property-tile>
      </p-panel-properties>
      <p-panel-properties>
        <p-property-tile icon="mdi-border-all-variant" title="Border Width">
          <p-panel-input-number style="max-width: 10rem;" :min="0" v-model="value.data.borderWidth"></p-panel-input-number>
        </p-property-tile>
      </p-panel-properties>
    </p-panel-properties-group>
  </fragment>
</template>
<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import { Fragment } from "vue-fragment"
import FontGlobalForm from "../_components/panel/FontGlobalForm.vue"
import { fontSizesNumbers } from "../_components/panel/fontGlobalCommon"
import TextAlignmentForm from "../_components/panel/TextAlignmentForm.vue"
import PPanelProperties from "../_components/utils/PPanelProperties.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputNumber from "../_components/utils/PPanelInputNumber.vue"
import PresetInputColor from "../../components/slides/styles/PresetInputColor.vue"

export default {
  components: {
    PPanelPropertiesGroup,
    Fragment,
    FontGlobalForm,
    TextAlignmentForm,
    PresetInputColor,
    PPanelProperties,
    PPropertyTile,
    PPanelInputNumber
  },
  props: ["value"],
  data() {
    return {
      fontSizesNumbers
    }
  }
}
</script>
