import { BlockBasic } from "../_models/models.js"

/**
 * Large textarea block with format
 */
export default class BlockTextarea extends BlockBasic {
  constructor(block) {
    super(block)
    if (!block) {
      this.type = "textarea"
      this.size.width = 700
      this.size.height = 400
      this.protection.type = ["textarea"]
      this.data = {
				html: '<p>Textarea block</p>',
				fontSize: 72,
				color: '#000000ff',
				/* left, center, right, justify */
				textAlign: 'center',
				/* flex-start, center, flex-end */
				alignItems: 'center',
				fontFamily: false,
				padding: [10, 10, 10, 10],
				paddingLocked: true,
				paragraphSpacing: 16,
				backgroundColor: '#00000000',
				borderColor: '#00000000',
				borderRadius: 0,
				borderWidth: 0
			};
    } else {
      Object.assign(this, block)
    }
  }
}
