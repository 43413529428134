import katex from 'katex';
import { cleanHTMLContent } from '../../utils';
function renderKatex(previewDiv) {
	if (!previewDiv) return;
	renderKatexInline(previewDiv);
	previewDiv.querySelectorAll('katex').forEach(el => {
		katex.render(el.getAttribute('content'), el, {
			throwOnError: false,
			strict: false,
			displayMode: true,
			maxSize: 300,
			output: 'mathml'
		});
	});
}
function renderKatexByClass(previewDiv) {
	setTimeout(() => {
		if (!previewDiv) return;
		previewDiv.querySelectorAll('.katex-wrapper').forEach(el => {
			el.innerHTML = '<p>' + el.querySelector('.katex').outerHTML + '</p>';
		});
		renderKatexInline(previewDiv);
	}, 1);
}

function renderKatexInline(previewDiv) {
	if (!previewDiv) return;
	previewDiv.querySelectorAll('span[data-inline-katex]').forEach(el => {
		if (el.childElementCount > 0) {
			return false;
		}
		const content = cleanHTMLContent(el.innerHTML);
		katex.render(content, el, {
			throwOnError: false,
			strict: false,
			displayMode: false,
			maxSize: 300,
			output: 'mathml'
		});
	});
}

export { renderKatex, renderKatexByClass };
