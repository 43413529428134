<template>
	<basic-block :value="value" :mode="mode" :state.sync="state">
		<template v-slot:main>
			<portal :to="`textarea-toolbar-${value.id}`" v-if="mode === 'edit'">
				<toolbar-textarea
					v-if="state === 'edit-content'"
					:editor="editor"
					:data="value.data"
					:id="value.id"
					:dark="blockController.dark"
					:blockVariables="blockVariables"
				/>
			</portal>
			<div 
				v-if="mode === 'edit'" 
				class="lp-block-textarea-container" 
				:style="{...backgroundStyle, ...borderStyle}"
				@click.stop="changeToEditable"
			>
				<editor-content
					v-if="editable"
					class="lp-block-textarea"
					:class="{ 'lp-block-textarea-edit': mode === 'edit' }"
					:editor="editor"
					:style="style"
				/>
				<div v-else class="lp-block-textarea" :style="style">
					<component :is="textAreaBlock" :value="value" :blockVariables="blockVariables" :mode="mode" />
				</div>
			</div>
			<div v-else class="lp-block-textarea-container" :style="{...backgroundStyle, ...borderStyle}">
				<div class="lp-block-textarea" :style="style">
					<component :is="textAreaBlock" :value="value" :blockVariables="blockVariables" :mode="mode" />
				</div>
			</div>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import ToolbarTextarea from './ToolbarTextarea.vue';
import { EditorContent } from '@tiptap/vue-2';
import textarea from './mixins/textarea.vue';
import { changeFontGlobal, getNodes, getStringFromNodes } from './utils.js';

import { renderKatex } from 'plant-common';

export default {
	name: 'BlockTextarea',
	inject: ['blockController', 'blockGlobals', 'lessonPlanController'],
	props: ['value', 'mode', 'blockVariables'],
	components: {
		EditorContent,
		BasicBlock,
		ToolbarTextarea
	},
	mixins: [textarea],
	data() {
		return {
			editor: null,
			editable: false,
			state: 'not-selected'
		};
	},
	computed: {
		textAreaBlock() {
			return {
				template: `
          <div class="view">
          <div v-html="valueHtmlTransformation" ref="tiptap"></div>
          </div>`,
				props: ['value', 'blockVariables', 'mode'],
				computed: {
					valueHtmlTransformation() {
						let valueHtml = this.value.data.html;
						if (this.blockVariables) {
							this.blockVariables.forEach(bVar => {
								valueHtml = valueHtml.replace('{{' + bVar.name + '}}', bVar.value);
							});
						}
						return valueHtml;
					}
				},
				mounted() {
					this.renderFormulas();
				},
				methods: {
					renderFormulas() {
						if (!this.$refs.tiptap) return;
						renderKatex(this.$refs.tiptap);
					}
				}
			};
		},
		style() {
			const [top, right, bottom, left] = this.value.data.padding;

			return {
				textAlign: this.value.data.textAlign,
				alignItems: this.value.data.alignItems,
				fontSize: `${this.value.data.fontSize}px`,
				color: this.value.data.color,
				padding: `${top}px ${right}px ${bottom}px ${left}px`
			};
		},
		textareaCssParagraphSpacing() {
			const marginBottom = this.value.data.paragraphSpacing;
			return `.${this.value.type}-${this.value.id} .lp-block-textarea .ProseMirror p,
      .${this.value.type}-${this.value.id} .lp-block-textarea .view p {
        margin-bottom: ${marginBottom}px;
      }`;
		},
		fieldContentTitle() {
			return this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.id === 'content_title')
				: null;
		},
		fieldContentCustomId() {
			return this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.id === 'content_custom_id')
				: null;
		},
		fieldSlideTitle() {
			return this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.id === 'slide_title')
				: null;
		},
		fieldSlideParentTitle() {
			return this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.id === 'slide_parent_title')
				: null;
		},
		fieldSlideNumberTitle() {
			return this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(field => field.id === 'slide_number')
				: null;
		},
		backgroundStyle() {
			return { 'background-color': this.value.data.backgroundColor || 'rgba(0, 0, 0, 0)' }
		},
		borderStyle() {
			const borderColor = this.value.data.borderColor || 'rgba(0, 0, 0, 0)'
			const borderRadius = this.value.data.borderRadius || 0
			const borderWidth = this.value.data.borderWidth || 0

			return { 'box-shadow': `inset 0px 0px 0px ${borderWidth}px ${borderColor}`, 'border-radius': `${borderRadius}px` }
		}
	},
	created() {
		if (!Array.isArray(this.value.data.padding)) {
			this.value.data.padding = [
				this.value.data.padding,
				this.value.data.padding,
				this.value.data.padding,
				this.value.data.padding
			];
		}

		this.fixWrongPaddingConversion();

		if (!this.value.data.hasOwnProperty('paddingLocked')) this.$set(this.value.data, 'paddingLocked', true);
		if (!this.value.data.hasOwnProperty('paragraphSpacing')) this.$set(this.value.data, 'paragraphSpacing', 16);

		this.initBlockCss();
	},
	watch: {
		state() {
			if (this.state === 'edit-content') {
				this.initEditor();
			} else {
				this.editable = false;
			}
		},
		fieldContentTitle() {
			if (this.fieldContentTitle && this.isDataField('content_title')) {
				this.initEditor();
			}
		},
		fieldContentCustomId() {
			if (this.fieldContentCustomId && this.isDataField('content_custom_id')) {
				this.initEditor();
			}
		},
		fieldSlideTitle() {
			if (this.fieldSlideTitle && this.isDataField('slide_title')) {
				this.initEditor();
			}
		},
		fieldSlideParentTitle() {
			if (this.fieldSlideParentTitle && this.isDataField('slide_parent_title')) {
				this.initEditor();
			}
		},
		fieldSlideNumberTitle() {
			if (this.fieldSlideNumberTitle && this.isDataField('slide_number')) {
				this.initEditor();
			}
		}
	},
	beforeDestroy() {
		if (this.editor) this.editor.destroy();
	},
	methods: {
		initEditor() {
			if (this.editor) {
				this.editor.destroy();
			}
			this.updateDataFields(this.value.data);
			this.editor = this.setEditor(this.value.data);
			changeFontGlobal(this.value.id, this.value.data);
		},
		initBlockCss() {
			const style = document.createElement('style');
			style.id = `${this.value.type}-${this.value.id}`;
			style.type = 'text/css';
			style.innerHTML = this.textareaCssParagraphSpacing;
			document.head.appendChild(style);
		},
		changeToEditable() {
			this.editable = this.state === 'edit-content';
		},
		updateDataFields(data) {
			const nodes = getNodes(data.html);
			const dataFields = nodes.querySelectorAll('[data-field]');
			dataFields.forEach(dataField => {
				const updatedValue = this.getValueField(dataField);
				if (updatedValue) {
					dataField.innerHTML = updatedValue;
				}
			});
			data.html = getStringFromNodes(nodes);
		},
		getValueField(dataField) {
			const updatedValue = this.lessonPlanController.fields
				? this.lessonPlanController.fields.find(f => f.id === dataField.dataset.field)
				: null;
			return updatedValue ? updatedValue.label : null;
		},
		isDataField(dataField) {
			const nodes = getNodes(this.value.data.html);
			const slideTitle = nodes.querySelectorAll(`[data-field='${dataField}']`);
			return slideTitle.length > 0;
		},
		fixWrongPaddingConversion() {
			// Fix block conversion
			// [0, 0, 16, 0] to [0, 0, 0, 0]
			// [10, 10, 26, 10] to [10, 10, 10, 10]
			if (
				this.value.data.padding[0] === this.value.data.padding[1] &&
				this.value.data.padding[0] === this.value.data.padding[3] &&
				this.value.data.padding[0] + 16 === this.value.data.padding[2]
			) {
				this.value.data.padding[2] = this.value.data.padding[0];
			}
		}
	}
};
</script>

<style>
.lp-block-textarea-container {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	-ms-overflow-style: none; /* Hide scrollbar IE and Edge */
	scrollbar-width: none; /* Hide scrollbar Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.lp-block-textarea-container::-webkit-scrollbar {
	display: none;
}

.lp-block-textarea {
	font-size: 80px;
	line-height: 1.2;
	display: flex;
	width: 100%;
	height: 100%;
}

.lp-block-textarea .view p:last-child,
.lp-block-textarea .ProseMirror p:last-child {
	margin-bottom: 0;
}

.lp-block-textarea .ProseMirror,
.lp-block-textarea .view {
	width: 100%;
	word-wrap: break-word;
	white-space: pre-wrap;
}

.lp-block-textarea .ProseMirror > *,
.lp-block-textarea .view > * {
	width: 100%;
}

.lp-block-textarea ul,
.lp-block-textarea ol {
	padding-left: 80px;
	display: inline-block;
}

.lp-block-textarea-edit .fields:hover {
	background-color: #eeeeee;
	position: relative;
}

/* Tooltip */
.lp-block-textarea-edit .fields:before {
	content: attr(data-field);
	position: absolute;
	bottom: 100%;
	transform: translateX(50%);
	right: 50%;
	padding: 10px;
	width: 100%;
	min-width: 300px;
	background-color: #ffffff;
	text-align: left;
	font-size: 30px;
	display: none;
}

.lp-block-textarea-edit .fields:hover:before {
	display: block;
}

.lp-block-textarea-edit span[data-inline-katex] {
	background: rgb(235, 235, 235);
	padding: 2px;
	display: inline-block;
}

.lp-block-textarea-edit .katex-wrapper textarea {
	font-size: 2.2rem;
}

.lp-block-textarea-edit .katex-component__title {
	font-size: 30px;
}

.lp-block-textarea-edit .katex-component__title .v-icon {
	font-size: 42px;
}

.lp-block-textarea-edit .katex-component__title button {
	width: 48px !important;
	height: 48px;
}
</style>
