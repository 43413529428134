import { BlockBasic } from "../_models/models.js"

/**
 * A Block showing content from the libary.
 */
export default class BlockLibraryContent extends BlockBasic {
  constructor(block) {
    super(block)
    if (!block) {
      this.type = "libraryContent"
      this.size.width = 700
      this.size.height = 400
      this.protection.type = ["libraryContent"]
      this.data = {
        content_ref: {},
        pinned_view: {},
        show_library_toolbar: false,
        show_fab: false,
        video_autoplay: false,
        viewer_mode: "basic",
        datasheet_related_content_filter_mode: "auto",
        datasheet_related_content_sorted: []
      }
    } else {
      Object.assign(this, block)
    }
  }
}
