import { TextStyle } from '@tiptap/extension-text-style';

export const FontSize = TextStyle.extend({
	name: 'fontSize',
	addAttributes() {
		return {
			...this.parent?.(),
			size: {
				default: null,
				parseHTML: element => {
					const fontSize = element.style.fontSize;
					return fontSize ?? null;
				},
				renderHTML: attributes => {
					if (!attributes.size) return {};
					return { style: `font-size: ${attributes.size}` };
				}
			}
		};
	},
	addCommands() {
		return {
			setFontSize:
				size =>
				({ chain }) => {
					if (!size) return false;
					return chain().setMark('fontSize', { size: size }).run();
				}
		};
	}
});
