import uuid from "../../../../utils/uuid.js"
import { BlockBasic } from "../_models/models.js"

export default class BlockTabs extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "tabs"
    this.size.width = 1200
    this.size.height = 600
    this.data = {
      tabs: [new BlockTab()],
      fontFamily: false,
      fontSize: 40,
      style: {
        tabs: {
          fullSize: false,
          normal: {
            backgroundColor: "#eeeeeeff",
            color: "#000000e6",
            spaceBetween: 8,
            paddingText: 4,
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#888888ff",
          },
          active: {
            backgroundColor: "#ffffffff",
            color: "#000000ff"
          },
          visited: {
            backgroundColor: "#ccccccff",
            color: "#000000ff"
          },
        },
        arrows: {
          normal: {
            backgroundColor: "#ffffffff",
            color: "#000000e6",
          },
          hover: {
            backgroundColor: "#cccccc",
            color: "#000000e6",
          },
        },
        canvas: {
          normal: {
            backgroundColor: "#ffffffff"
          },
        },
      }
    }
  }
}

export class BlockTab {
  constructor() {
    this.id = uuid()
    this.title = "Tab"
    this.blocks = []
  }
}
