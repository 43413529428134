<template>
	<div>
		<v-menu offset-y v-if="itemRevision">
			<template v-slot:activator="{ on }">
				<v-btn icon v-on="on">
					<v-icon>more_vert</v-icon>
				</v-btn>
			</template>

			<v-list v-if="itemRevision">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<span v-on="on">
							<v-list-tile @click="createDraft()" v-if="!isStudent" :disabled="contentHasDraft">
								<v-list-tile-avatar>
									<v-icon>device_hub</v-icon>
								</v-list-tile-avatar>
								<v-list-tile-title e2e-attr="workflow-option">Create new revision</v-list-tile-title>
							</v-list-tile>
						</span>
					</template>
					<span v-if="contentHasDraft">This content already has a draft</span>
					<span v-else>Create a new draft</span>
				</v-tooltip>
				<v-list-tile @click="viewWorkflow()">
					<v-list-tile-avatar>
						<v-icon>mdi-information</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title e2e-attr="workflow-option">View properties</v-list-tile-title>
				</v-list-tile>
				<template v-if="getType(itemRevision) == 'lessonPlan'">
					<v-list-tile
						:href="interactivePresentationAppUrl"
						target="_blank">
						<v-list-tile-avatar>
							<v-icon>mdi-rocket</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title>Open in Interactive Presentation app</v-list-tile-title>
					</v-list-tile>
				</template>
				<v-list-tile @click="viewWorkflow('tab-datasheet-dependents')">
					<v-list-tile-avatar>
						<v-icon>mdi-graph</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-title e2e-attr="workflow-option">View dependents</v-list-tile-title>
				</v-list-tile>
				<div v-if="!isStudent">
					<v-list-tile @click="createNewDraftFrom()">
						<v-list-tile-avatar>
							<v-icon>mdi-content-duplicate</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title e2e-attr="workflow-option">Copy content to a new draft</v-list-tile-title>
					</v-list-tile>
					<v-list-tile
						v-if="
							(getType(itemRevision) == 'guide' || getType(itemRevision) == 'lessonPlan') &&
							isScormAvailable &&
							(isAdmin || isSupervisor)
						"
						@click="callBuildScorm('1.2')">
						<v-list-tile-avatar>
							<v-icon>mdi-download-outline</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title e2e-attr="download-scorm-option">Download SCORM</v-list-tile-title>
					</v-list-tile>
					<v-list-tile
						v-if="
							(getType(itemRevision) == 'guide' || getType(itemRevision) == 'lessonPlan') &&
							isScormAvailable &&
							(isAdmin || isSupervisor)
						"
						@click="callBuildScorm('2004v3')">
						<v-list-tile-avatar>
							<v-icon>mdi-zip-box</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title e2e-attr="download-scorm2004v3-option">
							Download SCORM (2004 3rd Edition)
						</v-list-tile-title>
					</v-list-tile>
					<v-list-tile
						v-if="
							(getType(itemRevision) == 'guide' || getType(itemRevision) == 'lessonPlan') &&
							isScormAvailable &&
							(isAdmin || isSupervisor)
						"
						@click="callBuildScorm('2004v4')">
						<v-list-tile-avatar>
							<v-icon>mdi-zip-box-outline</v-icon>
						</v-list-tile-avatar>
						<v-list-tile-title e2e-attr="download-scorm2004v4-option">
							Download SCORM (2004 4th Edition)
						</v-list-tile-title>
					</v-list-tile>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { PtModal } from "plant-common"
import contentActions from "../../../components/commonContentActions/contentActions.vue"
import DownloadStatusModal from "./DownloadStatusModal.vue"
import {
	STORE_CONTENT_NAME,
	STATE_ITEM_REVISION,
	STATE_CAN_EDIT,
	ACTION_CHECK_DRAFT,
	ACTION_CREATE_DRAFT_FROM_REVISION,
	ACTION_GET_DEPENDENTS,
	ACTION_COPY_CONTENT_FROM_REVISION,
	ACTION_BUILD_SCORM,
	ACTION_COPY_CONTENT_FROM_APPROVED_REVISION
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { STORE_USER_NAME, GETTER_IS_ADMIN, GETTER_IS_SUPERVISOR, GETTER_IS_STUDENT } from "@/store_constants/user"
import {
	STORE_EXPLORER_NAME,
	ACTION_GET_ALL_LIBRARY_FOLDERS,
	STATE_ALL_LIBRARY_FOLDERS
} from "@/store_constants/explorer"
import { getContentId, getType, getMajorVersion, getMinorVersion, getFolder, getPath } from "../../../utils/dbTool"
import WorkflowTypes from "../../workflow/constants/WorkflowTypes"

const { VUE_APP_SCORM_DOWNLOAD_AVAILABLE } = window.__env__
export default {
	components: {},
	mixins: [PtModal, contentActions],
	data() {
		return {
			changed: false,
			workflows: WorkflowTypes,
			contentHasDraft: false
		}
	},
	async created() {
		await this.getAllLibraryFolders()
	},
	computed: {
		...mapState(STORE_CONTENT_NAME, {
			itemRevision: STATE_ITEM_REVISION,
			canEdit: STATE_CAN_EDIT
		}),
		...mapState(STORE_LIBRARY_NAME, {
			settings: STATE_PLANT_SETTINGS
		}),
		...mapGetters(STORE_USER_NAME, {
			isAdmin: GETTER_IS_ADMIN,
			isSupervisor: GETTER_IS_SUPERVISOR,
			isStudent: GETTER_IS_STUDENT
		}),
		...mapState(STORE_EXPLORER_NAME, {
			allLibraryFolders: STATE_ALL_LIBRARY_FOLDERS
		}),
		comments() {
			return this.itemRevision.approval_process.comments
		},
		itemPath() {
			return getPath(this.itemRevision, this.allLibraryFolders)
		},
		references() {
			if (this.itemRevision) {
				return this.itemRevision.body.references
			} else {
				return []
			}
		},
		isScormAvailable() {
			return VUE_APP_SCORM_DOWNLOAD_AVAILABLE == "true"
		},
		interactivePresentationAppUrl() {
			const libraryId = this.settings._id
			const contentId = getContentId(this.itemRevision)
			const revision = `${getMajorVersion(this.itemRevision)}.${getMinorVersion(this.itemRevision)}`

			return `/apps/interactive-presentation-app/index.html?libraryId=${libraryId}&contentId=${contentId}&revision=${revision}`
		}
	},
	watch: {
		itemRevision: {
			immediate: true,
			deep: true,
			async handler() {
				this.contentHasDraft = (await this.checkDraft(getContentId(this.itemRevision))) ? true : false
			}
		}
	},
	methods: {
		...mapActions(STORE_CONTENT_NAME, {
			createDraftFromItemRevision: ACTION_CREATE_DRAFT_FROM_REVISION,
			getDependents: ACTION_GET_DEPENDENTS,
			copyContentFromRevision: ACTION_COPY_CONTENT_FROM_REVISION,
			copyContentFromApprovedRevision: ACTION_COPY_CONTENT_FROM_APPROVED_REVISION,
			buildScorm: ACTION_BUILD_SCORM,
			checkDraft: ACTION_CHECK_DRAFT
		}),
		...mapActions(STORE_EXPLORER_NAME, {
			getAllLibraryFolders: ACTION_GET_ALL_LIBRARY_FOLDERS
		}),
		getType: getType,
		getFolder: getFolder,
		async createDraft() {
			let commentsToImport = ""
			if (this.comments.length) {
				commentsToImport = await this.$modal("optionsList", {
					title: "This revision has comments",
					message: "Do you want to copy them to the new draft?",
					options: [
						{ text: "No", value: "none" },
						{ text: "Copy all comments", value: "all" },
						{ text: "Copy only opened comments", value: "opened" }
					]
				})
				if (!commentsToImport) return
			}

			this.createDraftFromItemRevision(commentsToImport)
				.then(draft => {
					window.open(`/content/${this.settings._id}/${getContentId(this.itemRevision)}/draft`)
				})
				.catch(err => {
					console.log(err)
				})
		},
		async viewWorkflow(selectedTab = "") {
			const propsData = {
				title: `Workflow log`,
				approval_process: this.itemRevision.approval_process,
				workflows: this.workflows,
				item: this.itemRevision,
				path: this.itemPath,
				header: this.itemRevision.header,
				settings: this.settings,
				isDraft: false,
				dependents: await this.getDependents()
			}
			if (selectedTab !== "") {
				propsData.selectedTab = selectedTab
			}

			this.$modal("workflow", propsData)
		},
		createNewDraftFrom() {
			this.createNewDraftFromContent(this.itemRevision)
		},
		async callBuildScorm(version) {
			try {
				const downloadResponse = await this.buildScorm({
					contentId: getContentId(this.itemRevision),
					majorRev: this.itemRevision.header.major_revision,
					minorRev: this.itemRevision.header.minor_revision,
					version: version ? version : "1.2"
				})
				var res = await this.$modal(DownloadStatusModal, {
					response: downloadResponse,
					value: true,
					version: version ? version : "1.2"
				})
				if (!res) return false
			} catch (e) {
				console.log(e)
			}
		}
	}
}
</script>
