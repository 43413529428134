var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-block',{attrs:{"value":_vm.value,"mode":_vm.mode,"state":_vm.state},on:{"update:state":function($event){_vm.state=$event}},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{staticClass:"p-tabs"},[_c('div',{staticClass:"p-tabs-headers",class:{
					'padding-left': _vm.scroll > 0 && !_vm.isFullSizeTabs,
					'padding-right': _vm.canScroll && !_vm.isFullSizeTabs,
					'full-size-tabs': _vm.isFullSizeTabs
				},style:(_vm.style)},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.scroll > 0 && !_vm.isFullSizeTabs),expression:"scroll > 0 && !isFullSizeTabs"}],staticClass:"action-tab prev",style:(_vm.arrowStyle),attrs:{"align":"center"},on:{"click":function($event){return _vm.applyScroll(-_vm.w)}}},[_c('v-icon',{staticClass:"tab-prev",style:(_vm.arrowStyle)},[_vm._v(" mdi-arrow-left ")])],1),_vm._l((_vm.value.data.tabs),function(tab){return _c('div',{key:tab.id,staticClass:"p-tabs-header no-right",class:{
						'p-tabs-header-active': _vm.tabItem === tab.id,
						'two-lines': _vm.isFullSizeTabs
					},style:(_vm.titleStyle),on:{"click":function($event){$event.stopPropagation();return _vm.changeTabItem(tab.id, $event)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),(_vm.mode === 'edit')?_c('span',{staticClass:"p-tabs-header add-new-tab no-right no-grow",style:(_vm.titleStyle),on:{"click":function($event){$event.stopPropagation();return _vm.addNewTab()}}},[_c('v-icon',{style:(_vm.titleStyle)},[_vm._v(" mdi-plus ")])],1):_vm._e(),(_vm.canScroll && !_vm.isFullSizeTabs)?_c('span',{staticClass:"action-tab next",style:(_vm.arrowStyle),on:{"click":function($event){return _vm.applyScroll(_vm.w)}}},[_c('v-icon',{staticClass:"tab-next",style:(_vm.arrowStyle)},[_vm._v(" mdi-arrow-right ")])],1):_vm._e()],2),_c('div',{staticClass:"p-tabs-items",style:(_vm.style)},[_vm._l((_vm.value.data.tabs),function(tab){return [(_vm.tabItem === tab.id)?_c('div',{key:tab.id,staticClass:"p-tab-item"},[(_vm.mode === 'edit')?_c('block-subcanvas',{attrs:{"value":_vm.value,"blocks":tab.blocks,"blockVariables":_vm.blockVariables,"mode":"edit"},on:{"create:draft":function($event){return _vm.$emit('create:draft', $event)}}}):(_vm.tabItem === tab.id)?_c('block-subcanvas-readonly',{attrs:{"blocks":tab.blocks,"mode":"view","blockVariables":_vm.blockVariables,"subcanvasDelay":_vm.subcanvasHasDelay ? _vm.tabsDelay : undefined},on:{"goToBookmarkTarget":function($event){return _vm.$emit('goToBookmarkTarget', $event)},"selectSlideById":function($event){return _vm.$emit('selectSlideById', $event)}}}):_vm._e()],1):_vm._e()]})],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }