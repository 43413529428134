<script>
import Vue from "vue"
import confirm from "./ModalConfirm"
import form from "./ModalForm"
import info from "./ModalInfo"
import input from "./ModalInput"
import options from "./ModalOptions"
import optionsList from "./ModalOptionsList"
import workflow from "./ModalWorkflow"
import dependencies from "./ModalDependencies"
import templatesList from "./ModalTemplatesList"

export const Modals = { confirm, form, info, input, options, optionsList, workflow, dependencies, templatesList }

export default {
  methods: {
    /**
     * Creates a modal dialog that returns a promise. Import as a Mixin to the object
     * @param type [strig or VueObject]
     * @param propsData
     *
     * @example
     *
     * this.$modal('input',{title:"Enter Folder Name"})
     *      .then(r=> alert(`The response is ${r}`))
     *@example
     *  var Test={
     *    template:`
     *      <v-dialog :value="true" persistent max-width="450">
     *      <v-card>
     *        <v-card-title class="headline">Test of custom Modal</v-card-title>
     *        <v-card-actions>
     *                      <v-btn color="success" block @click="$emit('input',true) ">
     *              <v-icon left>mdi-check</v-icon>accept
     *            </v-btn>
     *            <v-btn color="error" block  @click="$emit('input',false) ">
     *              <v-icon left>mdi-cancel</v-icon>cancel
     *            </v-btn>

     *        </v-card-actions>
     *      </v-card>
     *    </v-dialog>
     *    `
     *  }
     *  this.$modal(Test).then(r => alert(`The response is ${r}`));
     */
    $modal(type, propsData) {
      var ModalInstance
      return new Promise((resolve, reject) => {
        if (typeof type == "string") {
          if (!Modals[type]) {
            throw new Error(`[PLANT] Modal ${type} does not exist`)
            return
          }
          ModalInstance = Vue.extend(Modals[type])
        } else if (typeof type === "object") {
          ModalInstance = Vue.extend(type)
        } else {
          return
        }

        var modal = new ModalInstance({
          propsData
        })
        modal.$on("input", r => {
          modal.$destroy(true)
          modal.$el.remove()
          resolve(r)
        })
        modal.$mount()
      })
    }
  }
}
</script>

<style></style>
