import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "datasheet",
    title: "Datasheet",
    icon: require("./content-icon.svg"),
    category: "engineering",
    Viewer,
    Editor,
    Preview: Viewer
}

export const DATASHEET_RELATED_CONTENT_FILTER_MODE_AUTO = 'auto';
export const DATASHEET_RELATED_CONTENT_FILTER_MODE_HIDDEN = 'hidden';
export const DATASHEET_RELATED_CONTENT_FILTER_MODE_CUSTOM = 'custom';