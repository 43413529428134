<template>
	<basic-block :value="value" :mode="mode">
		<template v-slot:main>
			<portal :to="`librarycontent-toolbar-${value.id}`" v-if="mode === 'edit'">
				<block-toolbar-button
					:icon="pinnedSaved ? 'mdi-pin-off' : 'mdi-pin'"
					tooltip="Pin view"
					@click="pinView"
					v-if="isContentSelected"
					:disabled="pinnedSaved || changeContentProtection"
					:dark="blockController.dark"
				/>
				<block-toolbar-button
					icon="mdi-restore"
					tooltip="Reset Pin View"
					@click="resetPinView"
					:disabled="changeContentProtection"
					v-if="!pinnedSaved && isContentSelected"
					:dark="blockController.dark"
				/>
				<toolbar-library-revision
					:value="value"
					:currentRevision="currentRevision"
					:latestRevision="latestRevision"
					:disabled="changeContentProtection"
					v-if="isContentSelected"
					v-on="$listeners"
					@updateSlideLatestRevision="updateSlideLatestRevision"
					:dark="blockController.dark"
				/>
				<block-toolbar-button
					icon="mdi-arrow-expand-right"
					tooltip="Create minor version"
					@click="createMinorVersion"
					v-if="isContentSelected"
					:dark="blockController.dark"
				/>
				<block-toolbar-button
					icon="mdi-close-box-outline"
					tooltip="Remove content"
					@click="removeContent()"
					v-if="isContentSelected"
					:disabled="changeContentProtection"
					:dark="blockController.dark"
				/>
			</portal>
			<block-placeholder
				v-if="!isContentSelected"
				@click.native="mode === 'edit' ? addFromContentManager() : null"
				img="select_content"
				icon="mdi-folder-search"
				text="Select Content"
				:mode="mode"
			/>
			<div v-else class="lp-block-library-content-wrapper">
				<div v-if="isMenuActive" class="lp-block-library-content-menu">
					<v-list>
						<p-content-tile v-if="value.data.content_ref.header" :value="value.data.content_ref">
							<template v-slot:content>
								<div class="d-flex ml-4">
									<v-divider vertical></v-divider>
									<v-btn v-if="pinnedView" icon ripple @click="resetPinView" :disabled="pinnedSaved">
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-icon v-on="on">mdi-restore</v-icon>
											</template>
											<span>Reset Pin View</span>
										</v-tooltip>
									</v-btn>
								</div>
							</template>
							<template v-slot:append>
								<v-list-tile-action class="dependencies-list-action">
									<v-btn icon ripple @click="openDependency">
										<v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
									</v-btn>
								</v-list-tile-action>
							</template>
						</p-content-tile>
					</v-list>
				</div>
				<div class="lp-block-library-content-viewer">
					<item-viewer
						v-if="value.data.content_ref.header"
						@click.stop
						:value="value.data.content_ref"
						:key="value.id + '-itemViewer'"
						:view.sync="slideView"
						:viewer-mode="value.data.viewer_mode"
						:datasheet-related-content-filter-mode="value.data.datasheet_related_content_filter_mode"
						:datasheet-related-content-sorted="value.data.datasheet_related_content_sorted"
						:mode="mode === 'preview' ? mode : 'view'"
					/>
				</div>
				<v-btn v-if="isFABActive" @click="openDependency" small color="primary" dark absolute bottom right fab>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on">mdi-open-in-new</v-icon>
						</template>
						<span>Open content</span>
					</v-tooltip>
				</v-btn>
			</div>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import BlockToolbarButton from '../button/BlockToolbarButton.vue';
import { ItemViewer, PContentTile, Extensions, DATASHEET_RELATED_CONTENT_FILTER_MODE_AUTO } from 'plant-common';
import contentNotAllowed from '../contentNotAllowed';
import PServiceContent from 'plant-common/src/services/PServiceContent';
import { cloneDeep } from '../../utils/utils.js';
import ToolbarLibraryRevision from './ToolbarLibraryRevision.vue';
import { getCustomId, getType } from '../../../../utils/dbTool.js';
import ptApiClient from 'plant-api-client';
import BlockPlaceholder from '../_components/placeholders/BlockPlaceholder.vue';

const VIEWER_MODE_BASIC = 'basic';
const VIEWER_MODE_AUTO = 'auto';
const VIEWER_MODE_ADVANCED = 'advanced';

export default {
	name: 'BlockLibraryContent',
	inject: ['blockController', 'DependencyBus'],
	components: {
		BasicBlock,
		BlockToolbarButton,
		ItemViewer,
		ToolbarLibraryRevision,
		BlockPlaceholder,
		PContentTile
	},
	props: ['value', 'mode'],
	data() {
		return {
			slideView: null,
			currentRevision: this.getCurrentRevision(),
			latestRevision: this.getLatestRevision()
		};
	},
	computed: {
		isContentSelected() {
			return Object.keys(this.value.data.content_ref).length > 0;
		},
		isMenuActive() {
			return this.value.data.show_library_toolbar;
		},
		videoAutoplay() {
			return this.value.data.video_autoplay;
		},
		videoDelay() {
			return this.value.data.video_delay;
		},
		isFABActive() {
			return this.value.data.show_fab;
		},
		pinnedSaved() {
			return this.slideView && JSON.stringify(this.value.data.pinned_view) === JSON.stringify(this.slideView);
		},
		pinnedView() {
			return this.value.data.pinned_view;
		},
		changeContentProtection() {
			return this.value.protection.data;
		},
		isEditMode() {
			return this.mode === 'edit';
		},
		isVideo() {
			return this.isVideoContent || this.isYoutubeContent;
		},
		isVideoContent() {
			return this.value.data.content_ref && getType(this.value.data.content_ref) === 'video';
		},
		isYoutubeContent() {
			return this.value.data.content_ref && getType(this.value.data.content_ref) === 'youtubeVideo';
		}
	},
	created() {
		if (!this.value.data.show_library_toolbar) this.$set(this.value.data, 'show_library_toolbar', false);
		if (!this.value.data.video_autoplay) this.$set(this.value.data, 'video_autoplay', false);
		if (!this.value.data.show_fab) this.$set(this.value.data, 'show_fab', false);
		if (!this.value.data.viewer_mode) this.$set(this.value.data, 'viewer_mode', VIEWER_MODE_AUTO)
		if (!this.value.data.datasheet_related_content_filter_mode) this.$set(this.value.data, 'datasheet_related_content_filter_mode', DATASHEET_RELATED_CONTENT_FILTER_MODE_AUTO)
		if (!this.value.data.datasheet_related_content_sorted) this.$set(this.value.data, 'datasheet_related_content_sorted', [])
	},
	mounted() {
		this.initSlideView();
		this.addVideoPropertiesToSlideView();
		this.pinView();
	},
	watch: {
		slideView() {
			this.initPinnedView();
		},
		'value.data.content_ref': {
			handler() {
				this.initSlideView();
				this.addVideoPropertiesToSlideView();
				this.pinView();
        this.currentRevision = this.getCurrentRevision();
			}
		}
	},
	methods: {
		async addFromContentManager() {
			const contentTypes = Object.keys(Extensions).filter(el => {
				return contentNotAllowed.indexOf(el) < 0;
			});
			var newContentRef = await PServiceContent.selectContent({ type: contentTypes });
			if (!newContentRef) return;

			this.value.data.content_ref = newContentRef;
			this.value.has_activity = this.hasActivity(newContentRef);
		},
		hasActivity(newContentRef) {
			const activities = [
				'activityMultipleChoice',
				'activityFillTheGap',
				'activityDiagram',
				'activityFlashCard',
				'activityVr',
				'activityPicture'
			];
			return activities.includes(newContentRef.header.metadata.content_revision_type);
		},
		initPinnedView() {
			if (!this.value.data.pinned_view || Object.keys(this.value.data.pinned_view).length === 0) {
				this.pinView();
			}
		},
		initSlideView() {
			if (this.value.data.pinned_view && Object.keys(this.value.data.pinned_view).length > 0) {
				this.slideView = cloneDeep(this.value.data.pinned_view);
			}
		},
		addVideoPropertiesToSlideView() {
			if (this.isVideo) {
				if (this.videoAutoplay && !this.isEditMode) {
					if (this.slideView) {
						this.slideView = { ...this.slideView, autoplay: true, delay: this.videoDelay };
					} else {
						this.slideView = { autoplay: true, delay: this.videoDelay };
					}
				} else {
					this.slideView = {};
				}
			}
		},
		openDependency() {
			PServiceContent.openContent(this.value.data.content_ref, 'tab', JSON.stringify(this.value.data.pinned_view));
		},
		pinView() {
			this.value.data.pinned_view = cloneDeep(this.slideView);
		},
		resetPinView() {
			this.initSlideView();
		},
		removeContent() {
			this.value.data.content_ref = {};
			this.value.data.pinned_view = {};
			this.slideView = null;
		},
		getCurrentRevision() {
			if (!this.value.data || !this.value.data.content_ref) return '';
			const header = this.value.data.content_ref.header;
			if (!header) return '';
			return `${header.major_revision}.${header.minor_revision}`;
		},
		getLatestRevision() {
			return this.value.data && this.value.data.content_ref ? this.value.data.content_ref.__latest : null;
		},
		async updateSlideLatestRevision() {
      this.DependencyBus.$emit('updateDependencyFromLibraryContent', this.value.data.content_ref);
		},
		createMinorVersion() {
			if (this.value && this.value.data.content_ref) {
				this.$emit('create:draft', this.value.data.content_ref);
			}
		}
	}
};
</script>

<style>
/* Not show external drawers from diagram */
.lp-basic-block-slot-wrapper .fix-viewer {
	overflow: hidden;
}
.lp-block-library-content-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.lp-block-library-content-menu {
	border: 1px solid rgba(0, 0, 0, 0.12);
}

.lp-block-library-content-viewer {
	position: relative;
	flex: 1 1 auto;
	height: 100%;
	min-height: 0;
}
</style>
