const css = `/* This is a small skeleton to begin with, feel free to modify what yo need */

/* ******************************************** */
/* Desktop                                      */
/* ******************************************** */
.editor__desktop-page {
  background-color: rgb(240, 240, 240); /* Desktop Background color */
  padding: 20px 0; /* 20px top/bottom and 0px left/right */
  height: 100%;
  /* Background image */
}

/* ******************************************** */
/* Page                                         */
/* ******************************************** */
.editor__page {
  background-color: rgb(250, 250, 250); /* Page background color */
  width: 80%;
  max-width: 960px; /* Page max width */
  min-height: 100%;
  margin: auto; /* Center page */
  padding: 20px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1); /* Page shadow */
}
.editor__page table:not(.plant-library-component__wrapper table) {
  border-collapse: collapse;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
  margin: 1.5rem auto;
  overflow-x: auto;
}
.editor__page table td:not(.plant-library-component__wrapper table td),
.editor__page table th:not(.plant-library-component__wrapper table th) {
  border: 1px solid grey;
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}
.editor__page table td > *:not(.plant-library-component__wrapper table td > *),
.editor__page table th > *:not(.plant-library-component__wrapper table td > *) {
  margin-bottom: 0;
}
.editor__page table th:not(.plant-library-component__wrapper table th) {
  background-color: lightgrey;
  font-weight: bold;
  text-align: left;
}
.editor__page table .selectedCell:not(.plant-library-component__wrapper table .selectedCell):after {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}
.editor__page table th.selectedCell:not(.plant-library-component__wrapper table th.selectedCell):after {
  background: rgba(0, 0, 0, 0.32);
}
.editor__page table td.selectedCell:not(.plant-library-component__wrapper table td.selectedCell):after {
  background: rgba(212, 212, 212, 0.44);
}
.editor__page table .column-resize-handle:not(.plant-library-component__wrapper table .column-resize-handle) {
  background-color: darkslategrey;
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
}
.editor__page .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
 
/* ******************************************** */
/* Tags                                         */
/* ******************************************** */

/* Tag H1 (header 1)*/
h1 {
  color: black;
  margin-bottom: 5px;
}
/* Tag H2 (header 2)*/
h2 {
  color: black;
  margin-bottom: 5px;
}
/* Tag H3 (header 3)*/
h3 {
  color: black;
  margin-bottom: 5px;
}
/* Tag P (paragraph) */
p {
  color: black;
}

/* ******************************************** */
/* Headers autonumber                           */
/* ******************************************** */
.editor__page {
  counter-reset: section;
}
.editor__page h1:not(.plant-library-component__wrapper h1) {
  counter-reset: sub-section;
}
.editor__page h2:not(.plant-library-component__wrapper h2) {
  counter-reset: composite;
}
.editor__page h3:not(.plant-library-component__wrapper h3) {
  counter-reset: detail;
}

.editor__page h1:not(.plant-library-component__wrapper h1):before {
  counter-increment: section;
  content: counter(section) " ";
}
.editor__page h2:not(.plant-library-component__wrapper h2):before {
  counter-increment: sub-section;
  content: counter(section) "." counter(sub-section) " ";
}
.editor__page h3:not(.plant-library-component__wrapper h3):before {
  counter-increment: composite;
  content: counter(section) "." counter(sub-section) "." counter(composite) " ";
}
.editor__page h4:not(.plant-library-component__wrapper h4):before {
  counter-increment: detail;
  content: counter(section) "." counter(sub-section) "." counter(composite) "." counter(detail) " ";
}
`;

export default css
