var config = {
	tenant: window.__env__.VUE_APP_AUTH_TENANT,
	client_id: window.__env__.VUE_APP_AUTH_CLIENT_ID,
	redirect_uri: window.__env__.VUE_APP_AUTH_REDIRECT_URI,
	login_uri: window.__env__.VUE_APP_AUTH_LOGIN_URI
		? window.__env__.VUE_APP_AUTH_LOGIN_URI
		: 'https://login.microsoftonline.com'
};

export var login_url = `${config.login_uri}/${config.tenant}/oauth2/v2.0/authorize
?client_id=${config.client_id}
&response_type=id_token
&redirect_uri=${encodeURIComponent(config.redirect_uri)}
&response_mode=fragment
&scope=openid%20email%20profile
&state=12345
&nonce=7362CAEA-9CA5-4B43-9BA3-34D7C303EBA7`.replace('\r\n', '');