<template>
  <div class="it-toolbar-textarea" v-if="editor">
    <!-- UNDO   -->
    <tip-tap-toolbar-button icon="mdi-undo" tooltip="Undo" @click="editor.chain().focus().undo().run()" :dark="dark">
    </tip-tap-toolbar-button>

    <!-- REDO   -->
    <tip-tap-toolbar-button icon="mdi-redo" tooltip="Redo" @click="editor.chain().focus().redo().run()" :dark="dark">
    </tip-tap-toolbar-button>

    <v-divider vertical></v-divider>

    <!-- FORMATS   -->
    <v-flex xs3>
      <v-select :items="formats" :value="formatSelected" hide-details solo dense flat return-object @change="changeFormatSelected"></v-select>
    </v-flex>

    <v-divider vertical></v-divider>

    <!-- FONT SIZES   -->
    <template v-if="formatSelected && formatSelected.value === 'Paragraph'">
      <v-icon>mdi-format-size</v-icon>

      <v-select 
        style="width: 4.5rem; margin-left: 0.5rem;" 
        :items="fontSizesNumbers" 
        v-model="fontSizeSelected" 
        hide-details 
        solo 
        dense 
        flat 
        return-object
      ></v-select>

      <v-divider vertical></v-divider>
    </template>

		<!-- TEXT ALIGNMENT   -->
		<tip-tap-toolbar-button
			tooltip="Align left"
			@click="editor.chain().focus().setTextAlign('left').run()"
			:isActive="editor.isActive({textAlign: 'left'})"
			icon="mdi-format-align-left"
			:dark="dark"></tip-tap-toolbar-button>

		<tip-tap-toolbar-button
			tooltip="Align center"
			@click="editor.chain().focus().setTextAlign('center').run()"
			:isActive="editor.isActive({textAlign: 'center'})"
			icon="mdi-format-align-center"
			:dark="dark"></tip-tap-toolbar-button>

		<tip-tap-toolbar-button
			tooltip="Align right"
			@click="editor.chain().focus().setTextAlign('right').run()"
			:isActive="editor.isActive({textAlign: 'right'})"
			icon="mdi-format-align-right"
			:dark="dark"></tip-tap-toolbar-button>

		<tip-tap-toolbar-button
			tooltip="Align justify"
			@click="editor.chain().focus().setTextAlign('justify').run()"
			:isActive="editor.isActive({textAlign: 'justify'})"
			icon="mdi-format-align-justify"
			:dark="dark"></tip-tap-toolbar-button>

    <v-divider vertical></v-divider>

    <!-- LISTS   -->
    <tip-tap-toolbar-button
      tooltip="Bullet list"
      @click="editor.chain().focus().toggleBulletList().run()"
      :isActive="editor.isActive('bulletList')"
      icon="mdi-format-list-bulleted"
      :dark="dark"></tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      tooltip="Ordered list"
      @click="editor.chain().focus().toggleOrderedList().run()"
      :isActive="editor.isActive('orderedList')"
      icon="mdi-format-list-numbered"
      :dark="dark"></tip-tap-toolbar-button>

    <order-list-button :editor="editor" />

    <v-divider vertical></v-divider>

    <!-- STYLE   -->
    <tip-tap-toolbar-button
      icon="mdi-format-bold"
      tooltip="Bold"
      @click="editor.chain().focus().toggleBold().run()"
      :isActive="editor.isActive('bold')"
      :dark="dark">
    </tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      icon="mdi-format-italic"
      tooltip="Italic"
      @click="editor.chain().focus().toggleItalic().run()"
      :isActive="editor.isActive('italic')"
      :dark="dark">
    </tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      icon="mdi-format-underline"
      tooltip="Underline"
      @click="editor.chain().focus().toggleUnderline().run()"
      :isActive="editor.isActive('underline')"
      :dark="dark">
    </tip-tap-toolbar-button>

    <v-divider vertical></v-divider>

    <!-- TABLE   -->
		<tiptap-table-buttons :editor="editor" :dark="dark"></tiptap-table-buttons>

		<v-divider vertical></v-divider>

    <!-- MATH   -->
    <tip-tap-toolbar-button
      tooltip="Subscript"
      @click="setSubscript"
      :isActive="editor.isActive('subscript')"
      icon="mdi-format-subscript"
      :dark="dark"></tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      tooltip="Superscript"
      @click="setSuperscript"
      :isActive="editor.isActive('superscript')"
      icon="mdi-format-superscript"
      :dark="dark"></tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      tooltip="Math inline"
      @click="editor.chain().focus().toggleFormulaMark().run()"
      :isActive="editor.isActive('formulaMark')"
      icon="mdi-math-integral"
      :dark="dark" />
    <tip-tap-toolbar-button
      tooltip="Math box"
      @click="editor.chain().focus().addKatex().run()"
      :isActive="editor.isActive('formulaNode')"
      icon="mdi-math-integral-box"
      :dark="dark" />
    <v-divider vertical></v-divider>

    <tip-tap-toolbar-button
      tooltip="Add Library Content"
      @click="addFromContentManager"
      icon="mdi-book"
      :dark="dark"></tip-tap-toolbar-button>

    <tip-tap-toolbar-button
      :disabled="controller.isIT"
      tooltip="Add Interactive Text"
      @click="importITFromObjective"
      icon="mdi-text-shadow"
      :dark="dark"></tip-tap-toolbar-button>

    <v-divider vertical></v-divider>
  </div>
</template>

<script>
import TipTapToolbarButton from "plant-common/src/components/PRichText/TipTapToolbarButton"
import { OrderListButton } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { fontSizesNumbers } from "./utils/fontSizesNumbers"
import TiptapTableButtons from './TiptapTableButtons.vue';

export default {
  name: "InteractiveTextToolbar",
  inject: ["controller"],
  components: {
		TiptapTableButtons,
    TipTapToolbarButton,
    OrderListButton
  },
  props: ["editor", "data", "side", "id", "dark", "blockVariables"],
  data() {
    return {
      formatSelected: this.nodeSelected,
      formats: [
        {
          text: "Paragraph",
          value: "Paragraph",
          action: () => {
            this.editor.chain().focus().setParagraph().run()
            this.applyFontSize(`${this.fontSizeSelected}px`)
          }
        },
        {
          text: "Heading 1",
          value: "Heading 1",
          action: () => this.applyHeadingAction('Heading 1', 1)
        },
        {
          text: "Heading 2",
          value: "Heading 2",
          action: () => this.applyHeadingAction('Heading 2', 2)
        },
        {
          text: "Heading 3",
          value: "Heading 3",
          action: () => this.applyHeadingAction('Heading 3', 3)
        }
      ],
      defaultFontSize: 14,
      fontSizeSelected: this.defaultFontSize,
      fontSizesNumbers
    }
  },
  computed: {
    nodeSelected() {
      if (!this.editor || !this.formats) return undefined
      if (this.editor.isActive("paragraph")) return this.formats[0]
      if (this.editor.isActive("heading", { level: 1 })) return this.formats[1]
      if (this.editor.isActive("heading", { level: 2 })) return this.formats[2]
      if (this.editor.isActive("heading", { level: 3 })) return this.formats[3]
      return undefined
    }
  },
  watch: {
    fontSizeSelected(fontSize) {
      this.applyFontSize(`${fontSize}px`)
    },
    nodeSelected: {
      immediate: false,
      handler(newNodeSelected, oldNodeSelected) {
        if (!oldNodeSelected) return
        if (newNodeSelected) this.formatSelected = newNodeSelected
      }
    },
    editor() {
      if (!this.editor) return

      this.editor.on('selectionUpdate', (event) => {
        const currentSelection = event.transaction.curSelection

        const cursorPositionFrom = currentSelection.from
        const cursorPositionTo = currentSelection.to
        
        if (cursorPositionFrom != cursorPositionTo) return

        this.getSelectionFontSize()
      })
    }
  },
  mounted() {
    this.fontSizeSelected = this.defaultFontSize
  },
  methods: {
    async addFromContentManager() {
      const contentTypes = [
        "datasheet",
        "diagram",
        "document",
        "glbModel",
        "picture",
        "pointCloud",
        "video",
        "videovr",
        "vr"
      ]
      const newContentRef = await PServiceContent.selectContent({ type: contentTypes })
      if (!newContentRef) return

      this.editor.commands.insertContent(
        `<plant-library-component origin="${newContentRef.$origin}"></plant-library-component>`
      )
      if (!this.controller.page.htmlDependencies) this.controller.page.htmlDependencies = []
      this.controller.page.htmlDependencies.push(newContentRef)
    },
    async importITFromObjective() {
      const IT = await this.controller.importITFromObjective()
      if (!IT) return

      this.editor.commands.insertContent(`<interactive-text origin="${IT.$origin}"></interactive-text>`)
      if (!this.controller.page.htmlDependencies) this.controller.page.htmlDependencies = []
      this.controller.page.htmlDependencies.push(IT)
    },
    setSubscript() {
      this.editor.chain().focus().toggleSubscript().run()
      this.editor.chain().focus().unsetSuperscript().run()
    },
    setSuperscript() {
      this.editor.chain().focus().toggleSuperscript().run()
      this.editor.chain().focus().unsetSubscript().run()
    },
    changeFormatSelected(newFormatSelected) {
      this.formatSelected = newFormatSelected
      this.formatSelected.action()
    },
    applyHeadingAction(heading, level) {
      if (this.nodeSelected && this.nodeSelected.value === heading) return
      this.editor.chain().focus().toggleHeading({ level }).run()
    },
    applyFontSize(fontSize) {
      setTimeout(() => {
        if (!this.editor) return
        this.editor.chain().focus().setFontSize(fontSize).run()
      }, 0)
    },
    getSelectionFontSize() {
      const fontSize = this.editor.getAttributes('fontSize').size

      if (fontSize) {
        const fontSizeValue = fontSize.replace('px', '')
        this.fontSizeSelected = Number(fontSizeValue)
      } else {
        this.fontSizeSelected = this.defaultFontSize
      }
    }
  }
}
</script>

<style scoped>
.it-toolbar-textarea {
  display: flex;
  align-items: center;
  height: inherit;
}
</style>

<style >
.it-page h1 span,
.it-page h2 span,
.it-page h3 span {
  font-size: inherit !important;
}
</style>
