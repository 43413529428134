<template>
  <fragment>
    <p-panel-properties-group v-if="value && value.data && value.data.content_ref" title="Library content">
      <p-content-tile :value="value.data.content_ref">
        <template v-slot:append>
          <v-list-tile-action class="dependencies-list-action">
            <v-btn icon ripple @click="openDependency">
              <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
            </v-btn>
          </v-list-tile-action>
        </template>
      </p-content-tile>

      <p-property-tile title="Show toolbar">
        <v-switch class="justify-end" v-model="value.data.show_library_toolbar"></v-switch>
      </p-property-tile>

      <p-property-tile v-if="isVideo" title="Autoplay (Video without annotations)">
        <v-switch class="justify-end" v-model="value.data.video_autoplay"></v-switch>
      </p-property-tile>
      <p-property-tile icon="mdi-timer" title="Delay (seconds)" v-if="value.data.video_autoplay">
        <p-panel-input-number v-model="value.data.video_delay" :min="0" />
      </p-property-tile>

      <p-property-tile title="FAB: Open in new tab">
        <v-switch class="justify-end" v-model="value.data.show_fab"></v-switch>
      </p-property-tile>

			<p-property-tile v-if="isPictureContent" title="Image view mode">
				<template v-slot:default>
					<v-btn-toggle v-model="value.data.viewer_mode" mandatory>
						<v-btn outline value="basic">
							Basic
						</v-btn>
						<v-btn outline value="auto">
							Auto
						</v-btn>
						<v-btn outline value="advanced">
							Advanced
						</v-btn>
					</v-btn-toggle>
				</template>
			</p-property-tile>

			<p-property-tile v-if="isDatasheetContent" title="Related content viewer">
				<template v-slot:default>
					<v-btn-toggle v-model="value.data.datasheet_related_content_filter_mode" mandatory>
						<v-btn outline value="auto">
							Auto
						</v-btn>
						<v-btn outline value="hidden">
							Hidden
						</v-btn>
						<v-btn outline value="custom">
							Custom
						</v-btn>
					</v-btn-toggle>
				</template>
			</p-property-tile>

			<v-btn
				v-if="isDatasheetContent && isFilterModeCustom"
				class="px-2"
				block
				@click="openModal"
			>Customize related content</v-btn>
    </p-panel-properties-group>

		<v-dialog
			v-if="isDatasheetContent && isFilterModeCustom"
			:value="showModal"
			persistent
			max-width="800"
			width="80%"
		>
			<draggable-list-two-columns
				v-if="showModal"
				:target="relatedContentSorted"
				:origin="relatedContents"
				@close="showModal = false"
				@update:target="updateTarget"
			></draggable-list-two-columns>
		</v-dialog>
  </fragment>
</template>
<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputNumber from "../_components/utils/PPanelInputNumber.vue"
import { Fragment } from "vue-fragment"
import { PContentTile } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { getType, getCustomId, getLibraryId } from "../../../../utils/dbTool"
import DraggableListTwoColumns from './DraggableListTwoColumns.vue';

export default {
  name: "libraryContentPanel",
  components: {
		DraggableListTwoColumns,
    PPanelPropertiesGroup,
    PContentTile,
    PPropertyTile,
    PPanelInputNumber,
    Fragment
  },
  props: ["value"],
  data() {
    return {
			showModal: false,
			relatedContents: [],
			relatedContentSorted: []
		}
  },
  computed: {
    isVideo() {
      return this.isVideoContent || this.isYoutubeContent
    },
    isVideoContent() {
      return this.value.data.content_ref && getType(this.value.data.content_ref) === "video"
    },
    isYoutubeContent() {
      return this.value.data.content_ref && getType(this.value.data.content_ref) === "youtubeVideo"
    },
		isPictureContent() {
			return this.value.data.content_ref && getType(this.value.data.content_ref) === "picture"
		},
		isDatasheetContent() {
			return this.value.data.content_ref && getType(this.value.data.content_ref) === "datasheet"
		},
		isFilterModeCustom() {
			return this.value.data.datasheet_related_content_filter_mode === "custom"
		},
		datasheetRelatedContentSorted() {
			return this.value.data.datasheet_related_content_sorted
		}
  },
	watch: {
    isVideo() {
      if (!this.isVideo) {
        this.value.data.video_autoplay = false
      }
    },
    "value.data.video_autoplay": {
      immediate: true,
      handler() {
        if (this.value.data.video_autoplay && !this.value.data.video_delay) {
          this.$set(this.value.data, "video_delay", 0)
        }
      }
    }
  },
  methods: {
    openDependency() {
      PServiceContent.openContent(this.value.data.content_ref, "tab", JSON.stringify(this.value.data.pinned_view))
    },
		addActiveFieldToRelatedContent() {
			this.relatedContents.forEach((content) => content.isActive = true);
		},
		createInactiveContent(content) {
			const inactiveContent = { ...content };
			inactiveContent.isActive = false;
			inactiveContent.header = {
				metadata: {
					custom_id: inactiveContent.custom_id,
					title: "UNTAGGED"
				}
			}

			return inactiveContent;
		},
		buildLists() {
			this.datasheetRelatedContentSorted.forEach(content => {
				const contentIndex = this.relatedContents.findIndex(relatedContent => getCustomId(relatedContent) === content.custom_id );

				const contentIndexNotFound = contentIndex < 0;
				if (contentIndexNotFound) {
					this.relatedContentSorted.push(this.createInactiveContent(content));
				} else {
					const itemToMove = this.relatedContents.splice(contentIndex, 1)[0];
					this.relatedContentSorted.push(itemToMove);
				}
			})
		},
		prepareRelatedContentLists() {
			this.addActiveFieldToRelatedContent();

			if (!this.datasheetRelatedContentSorted.length) {
				return;
			}

			this.buildLists();
		},
		async openModal() {
			this.relatedContents = [];
			this.relatedContentSorted = [];

			await this.loadRelatedContent();
			this.prepareRelatedContentLists();

			this.showModal = true;
		},
		simplifyRelatedContentSorted(contents) {
			return contents.map((content) => {
				return {
					custom_id: getCustomId(content),
					isActive: content.isActive
				}
			})
		},
		updateTarget(contents) {
			this.showModal = false

			this.value.data.datasheet_related_content_sorted = this.simplifyRelatedContentSorted(contents);
		},
		getDatasheet() {
			return this.value.data.content_ref;
		},
		async loadRelatedContent() {
			const contentToSearch = ["picture", "vr", "video", "document"]
			const promises = [];
			contentToSearch.forEach(contentType => {
				promises.push(
					PServiceContent.findContent({
						library_id: getLibraryId(this.getDatasheet()),
						type: contentType,
						text: getCustomId(this.getDatasheet()),
						hotspot: 1
					})
				)
			});

			const contentsByTypeResults = await Promise.all(promises);
			const contentsByType = contentsByTypeResults.reduce((acc, result) => acc = [...acc, ...result.docs], []);
			contentsByType.forEach(contentType => {
				if (!this.fromContent || getCustomId(this.fromContent) !== getCustomId(contentType)) {
					this.relatedContents.push(contentType);
				}
			})
		}
  }
}
</script>

<style scoped>
.v-list__tile__action .v-input--selection-controls__input {
	margin-right: 0;
}
</style>