<template>
	<basic-block :value="value" :mode="mode" :state.sync="state">
		<template v-slot:main>
			<!-- Not using v-tabs to avoid conflict with  tabs from DiagramLeftDrawer -->
			<div class="p-tabs">
				<div
					:style="style"
					:class="{
						'padding-left': scroll > 0 && !isFullSizeTabs,
						'padding-right': canScroll && !isFullSizeTabs,
						'full-size-tabs': isFullSizeTabs
					}"
					class="p-tabs-headers"
				>
					<span v-show="scroll > 0 && !isFullSizeTabs" @click="applyScroll(-w)" align="center" :style="arrowStyle" class="action-tab prev">
						<v-icon :style="arrowStyle" class="tab-prev"> mdi-arrow-left </v-icon>
					</span>
					<div
						v-for="tab in value.data.tabs"
						:key="tab.id"
						:style="titleStyle"
						@click.stop="changeTabItem(tab.id, $event)"
						class="p-tabs-header no-right"
						:class="{
							'p-tabs-header-active': tabItem === tab.id,
							'two-lines': isFullSizeTabs
						}"
					>
						{{ tab.title }}
					</div>
					<span
						v-if="mode === 'edit'"
						@click.stop="addNewTab()"
						:style="titleStyle"
						class="p-tabs-header add-new-tab no-right no-grow"
					>
						<v-icon :style="titleStyle"> mdi-plus </v-icon>
					</span>
					<span v-if="canScroll && !isFullSizeTabs" @click="applyScroll(w)" class="action-tab next" :style="arrowStyle">
						<v-icon :style="arrowStyle" class="tab-next"> mdi-arrow-right </v-icon>
					</span>
				</div>

				<div class="p-tabs-items" :style="style">
					<template v-for="tab in value.data.tabs">
						<div class="p-tab-item" v-if="tabItem === tab.id" :key="tab.id">
							<block-subcanvas
								v-if="mode === 'edit'"
								:value="value"
								:blocks="tab.blocks"
								:blockVariables="blockVariables"
								mode="edit"
								@create:draft="$emit('create:draft', $event)"
							/>
							<block-subcanvas-readonly
								v-else-if="tabItem === tab.id"
								:blocks="tab.blocks"
								mode="view"
								@goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
								@selectSlideById="$emit('selectSlideById', $event)"
								:blockVariables="blockVariables"
								:subcanvasDelay="subcanvasHasDelay ? tabsDelay : undefined"
							/>
						</div>
					</template>
				</div>
			</div>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import BlockToolbarButton from '../button/BlockToolbarButton.vue';
import BlockSubcanvas from '../_components/containers/BlockSubcanvas.vue';
import BlockSubcanvasReadonly from '../_components/containers/BlockSubcanvasReadonly.vue';
import { BlockTab } from './class.js';
import _ from 'lodash';

export default {
	name: 'BlockTabs',
	inject: ['blockController'],
	props: ['value', 'mode', 'blockVariables'],
	components: {
		BasicBlock,
		BlockToolbarButton,
		BlockSubcanvas,
		BlockSubcanvasReadonly
	},
	data() {
		return {
			tabItem: this.value.data.tabs[0] ? this.value.data.tabs[0].id : -1,
			tabsVisited: [],
			state: 'not-selected',
			scroll: 0,
			canScroll: true,
			subcanvasHasDelay: false
		};
	},
	computed: {
		isFullSizeTabs() {
			return this.value.data.style.tabs.fullSize;
		},
		w() {
			if (this.$el) {
				const container = this.$el.querySelector('.p-tabs').getBoundingClientRect();
				return container.width;
			}
		},
		style() {
			return {
				fontFamily: this.value.data.fontFamily || 'inherit',
				'--color-canvas-background': this.value.data.style.canvas.normal.backgroundColor,
				'--background-tab-normal': this.value.data.style.tabs.normal.backgroundColor,
				'--background-tab-active': this.value.data.style.tabs.active.backgroundColor,
				'--color-tab-normal': this.value.data.style.tabs.normal.color,
				'--color-tab-active': this.value.data.style.tabs.active.color,
				'--border-radius': `${this.value.data.style.tabs.normal.borderRadius}px`,
				'--margin-right': `${this.value.data.style.tabs.normal.spaceBetween}px`,
				'--padding-right-left': `${this.value.data.style.tabs.normal.paddingText}px`,
				'--border-width': `${this.value.data.style.tabs.normal.borderWidth}px`,
				'--border-style': this.value.data.style.tabs.normal.borderStyle,
				'--border-color': this.value.data.style.tabs.normal.borderColor
			};
		},
		arrowStyle() {
			return {
				'--color-arrows-background': this.value.data.style.arrows.normal.backgroundColor,
				'--color-arrows-color': this.value.data.style.arrows.normal.color,
				'--color-arrows-background-hover': this.value.data.style.arrows.hover.backgroundColor,
				'--color-arrows-color-hover': this.value.data.style.arrows.hover.color,
				'--margin-negative': `${-1 * (2 + this.value.data.style.tabs.normal.borderWidth)}px`,
				fontSize: `${8 + this.value.data.fontSize}px`,
				height: `${40 + this.value.data.fontSize}px`
			};
		},
		titleStyle() {
			return {
				fontSize: `${this.value.data.fontSize}px`
			};
		},
		tabsDelay() {
			const animationIn =
				this.value.animations && this.value.animations[0] && this.value.animations[0].name !== 'none'
					? this.value.animations[0]
					: false;
			return animationIn ? parseFloat(animationIn.delay) + this.inheritDelay : undefined;
		},
		inheritDelay() {
			return this.$attrs.subcanvasDelay || 0;
		}
	},
	mounted() {
		if (!this.value.data.tabs[0]) {
			this.canScroll = false;
			return;
		}
		if (!this.isTabVisited(this.value.data.tabs[0].id) && this.mode !== 'edit') {
			this.tabsVisited.push(this.value.data.tabs[0].id);
			this.$set(this.value.data.tabs[0], 'visited', true);
		}
		this.updateBackgroundColor();
		this.updateCanScroll();

		if (this.$el) {
			this.$el.querySelector('.p-tabs-headers').addEventListener('scroll', event => {
				this.scroll = this.$el.querySelector('.p-tabs-headers').scrollLeft;
				this.updateCanScroll();
			});
		}
	},
	watch: {
		tabItem: {
			immediate: true,
			handler() {
				if (!_.isEmpty(this.blockController)) {
					this.blockController.$emit('childrenIdActive', this.tabItem);
				}
				this.updateBackgroundColor();
			}
		},
		'value.data.style': {
			deep: true,
			handler() {
				this.updateBackgroundColor();
			}
		},
		'value.data.tabs': {
			deep: true,
			handler() {
				this.$nextTick(() => {
					this.updateBackgroundColor();
					this.updateCanScroll();
				});
			}
		},
		'value.data.style.tabs.fullSize': {
			handler() {
				this.$nextTick(() => {
					if (!this.value.data.style.tabs.fullSize) {
						this.updateCanScroll();
					}
				});
			}
		},
		state: {
			immediate: true,
			handler() {
				if (!_.isEmpty(this.blockController)) {
					if (this.state === 'edit-content') {
						this.blockController.$emit('childrenIdActive', this.tabItem);
					} else if (this.state === 'not-selected') {
						this.blockController.$emit('childrenIdActive', null);
					}
				}
			}
		}
	},
	methods: {
		addNewTab() {
			this.value.data.tabs.push(new BlockTab());
			this.$nextTick(() => {
				this.updateCanScroll();
			});
		},
		applyScroll(px) {
			if (this.$el) {
				this.$el.querySelector('.p-tabs-headers').scrollLeft += px;
			}
		},
		isTabVisited(tabItem) {
			return this.tabsVisited.includes(tabItem);
		},
		changeTabItem(tabId, event) {
			this.subcanvasHasDelay = false;
			const tab = event.target.getBoundingClientRect();
			const container = this.$el.querySelector('.p-tabs').getBoundingClientRect();

			if (tab.x < container.x) this.applyScroll(-event.target.scrollWidth);
			if (tab.x > container.x && tab.x + tab.width > container.x + container.width)
				this.applyScroll(event.target.scrollWidth);

			this.blockController.selectionClear();
			this.blockController.selectionEdit(this.value.id);
			this.tabItem = tabId;
			if (!this.isTabVisited(tabId) && this.mode !== 'edit') {
				this.tabsVisited.push(tabId);
				this.$set(
					this.value.data.tabs.find(tab => tab.id === tabId),
					'visited',
					true
				);
			}
		},
		updateCanScroll() {
			if (this.$el) {
				const allTabs = this.$el.querySelectorAll('.p-tabs-header');
				const lastTab = allTabs[allTabs.length - 1].getBoundingClientRect();
				const container = this.$el.querySelector('.p-tabs').getBoundingClientRect();
				this.canScroll = container.x + container.width < lastTab.x + lastTab.width - 10;
			}
		},
		updateBackgroundColor() {
			// Background-color changed with JS because
			// the parent of active element must be changed and can't be done with CSS
			if (this.$el) {
				const titles = this.$el.querySelectorAll('.p-tabs-header');
				titles.forEach((title, index) => {
					let normalBgColor = this.value.data.style.tabs.normal.backgroundColor;
					let normalColor = this.value.data.style.tabs.normal.color;
					if (this.value.data.tabs[index] && this.isTabVisited(this.value.data.tabs[index].id)) {
						if (this.mode !== 'edit') {
							normalBgColor = this.value.data.style.tabs.visited.backgroundColor;
							normalColor = this.value.data.style.tabs.visited.color;
						}
						const backgroundColor =
							this.value.data.tabs[index].id === this.tabItem
								? this.value.data.style.tabs.active.backgroundColor
								: normalBgColor;
						const color =
							this.value.data.tabs[index].id === this.tabItem ? this.value.data.style.tabs.active.color : normalColor;
						title.style.backgroundColor = backgroundColor;
						title.style.color = color;
					}
				});
			}
		},
		firstTimeLoaded(tab) {
			if (!tab) return false;
			return !this.isTabVisited(tab.id) && this.isFirstTab(tab);
		},
		isFirstTab(tab) {
			return this.value.data.tabs[0].id === tab.id;
		}
	},
	created() {
		this.subcanvasHasDelay = this.firstTimeLoaded(this.value.data.tabs[0]);
		if (!_.isEmpty(this.blockController)) {
			this.blockController.$on(`selection-changed`, ({ block, state }) => {
				if (state === 'primary-selected') {
					var tabSelected = this.value.data.tabs.find(tab => tab.id === block.id);
					if (tabSelected) {
						this.tabItem = tabSelected.id;
					}
					var blockSelected = false;
					this.value.data.tabs.forEach(tab => {
						blockSelected = tab.blocks.find(tabBlock => tabBlock.id === block.id);
						if (blockSelected) {
							this.tabItem = tab.id;
						}
					});
				}
			});
		}
		if (!this.value.data.style.canvas) {
			this.$set(this.value.data.style, 'tabs', {
				active: {
					...this.value.data.style.active
				},
				visited: {
					...this.value.data.style.visited
				}
			});

			this.$set(this.value.data.style.tabs, 'normal', {
				...this.value.data.style.normal,
				spaceBetween: 8,
				paddingText: 4,
				borderRadius: 8,
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: '#888888ff'
			});

			this.$set(this.value.data.style.tabs, 'fullSize', false);

			this.$set(this.value.data.style, 'arrows', {
				normal: {
					backgroundColor: '#ffffffff',
					color: '#000000e6'
				},
				hover: {
					backgroundColor: '#cccccc',
					color: '#000000e6'
				}
			});
			this.$set(this.value.data.style, 'canvas', {
				normal: {
					backgroundColor: '#ffffffff'
				}
			});
		}
	}
};
</script>

<style>
.p-tabs {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.p-tabs-headers {
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	padding: 6px 0;
	margin: 0 calc(-1 * var(--border-width));
	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.p-tabs-headers.full-size-tabs {
	display: flex;
	white-space: normal;
	padding-bottom: 0;
	flex-grow: 1;
	flex-shrink: 0;
}
.p-tabs-headers.full-size-tabs > * {
	flex: 1;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.p-tabs-headers::-webkit-scrollbar {
	display: none;
}
.p-tabs-header {
	display: inline-block;
	text-align: center;
	color: var(--color-tab-normal);
	background-color: var(--background-tab-normal);
	cursor: pointer;
	padding: 8px var(--padding-right-left) 8px var(--padding-right-left);
	margin-right: var(--margin-right);
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	border-left: var(--border-width) var(--border-style) var(--border-color);
	border-top: var(--border-width) var(--border-style) var(--border-color);
	border-right: var(--border-width) var(--border-style) var(--border-color);
}
.p-tabs-header.two-lines {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
	align-content: center;
}
.no-right:last-child {
	margin-right: 0 !important;
}
.no-grow {
	flex: 0 !important;
}
.p-tabs-header-active {
	color: var(--color-tab-active) !important;
	background-color: var(--background-tab-active) !important;
	border-bottom: 1px solid var(--color-canvas-background) !important;
	margin-bottom: -1px;
	z-index: 99;
	font-weight: bold;
}
.p-tabs-items {
	height: 100%;
	outline: var(--border-width) var(--border-style) var(--border-color);
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}
.p-tab-item {
	height: 100%;
	position: relative;
	overflow: hidden;
	background-color: var(--color-canvas-background);
}
.action-tab {
	font-size: var(--font-size) !important;
	position: absolute;
	top: 0;
	z-index: 999;
	background-color: var(--color-arrows-background) !important;
	width: 60px;
	cursor: pointer;
	display: flex;
	justify-content: center;
}
.action-tab:hover {
	background-color: var(--color-arrows-background-hover) !important;
}
.v-icon.tab-next,
.v-icon.tab-prev {
	color: var(--color-arrows-color) !important;
}
.action-tab:hover .v-icon.tab-next,
.action-tab:hover .v-icon.tab-prev {
	color: var(--color-arrows-color-hover) !important;
}
.action-tab.prev {
	left: 0;
	margin-left: var(--margin-negative);
}
.action-tab.next {
	right: 0;
	margin-right: var(--margin-negative);
}
.padding-left {
	padding-left: 60px;
}
.padding-right {
	padding-right: 60px;
}
.v-list__tile__action .v-input--selection-controls__input {
	margin-right: 0;
}
</style>
